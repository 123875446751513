import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { icons } from '../../icons';

import './TravelerSpecializations.scss';

export const TravelerSpecializations = ({ specializations }) => {
  const { localize } = useTranslation();

  return (
    <div className="traveler-specs" id="traveler-specs">
      <h2 className="traveler-specs__title">{localize('traveler-test.result.traveler-specializations')}</h2>
      <div className="traveler-specs__list">
        {specializations?.map(({ name, description, key }) => (
          <div key={uuid.v4()} className="traveler-specs__spec">
            <div className="traveler-specs__spec-header">
              <img src={icons.travelerPersonality[key]} alt="spec" className="traveler-specs__icon" />
              <h3 className="traveler-specs__label">{name}</h3>
            </div>
            <div className="traveler-specs__text">{description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
