import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import './TravelPlanning.scss';

export const TravelPlanning = ({ personality }) => {
  const { localize } = useTranslation();
  const infoGathering = personality?.information_gathering;
  const planning = personality?.planning;

  return (
    <div className="travel-planning-container" id="travel-planning">
      <div className="travel-planning">
        <h2 className="travel-planning__title">{localize('traveler-test.result.travel-planning')}</h2>
        <div className="travel-planning__subtitle-white">
          <h2 className="travel-planning__label-bold">{infoGathering?.heading}</h2>
          <div className="travel-planning__text">{infoGathering?.text}</div>
        </div>
        <div className="travel-planning__subtitle">
          <h2 className="travel-planning__label">{infoGathering?.talent?.heading}</h2>
          <div className="travel-planning__text">{infoGathering?.talent?.text}</div>
        </div>
        <div className="travel-planning__subtitle">
          <h2 className="travel-planning__label">{infoGathering?.beware?.heading}</h2>
          <div className="travel-planning__text">{infoGathering?.beware?.text}</div>
        </div>
        <div className="travel-planning__subtitle-white">
          <h2 className="travel-planning__label-bold">{planning?.heading}</h2>
          <div className="travel-planning__text">{planning?.text}</div>
        </div>
        <div className="travel-planning__subtitle">
          <h2 className="travel-planning__label">{planning?.talent?.heading}</h2>
          <div className="travel-planning__text">{planning?.talent?.text}</div>
        </div>
        <div className="travel-planning__subtitle">
          <h2 className="travel-planning__label">{planning?.beware?.heading}</h2>
          <div className="travel-planning__text">{planning?.beware?.text}</div>
        </div>
      </div>
    </div>
  );
};
