import React, { useEffect, useCallback } from 'react';
import * as uuid from 'uuid';
import { getSwiperParams } from './swiper.config';
import { icons } from '../../icons';

import Swiper from 'react-id-swiper/lib/ReactIdSwiper.custom';

import './SwiperSection.scss';

export const SwiperSection = ({ title, items, labelWithIcon, id }) => {
  const onWindowResize = useCallback(() => {
    const params = getSwiperParams();
    const slides = document.querySelectorAll('.swiper-slide');
    slides.forEach((slide) => (slide.style.width = params.width + 'px'));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  });

  const renderItems = () => {
    return items?.map(({ name, description, key }) => (
      <div key={uuid.v4()} className="swiper-section__container">
        <div className="swiper-section__item swiper-item">
          <div className="swiper-item__header">
            {labelWithIcon && (
              <img src={icons.travelerPersonality[key]} alt="swiper item" className="swiper-item__icon" />
            )}
            <h3 className="swiper-section__label">{name}</h3>
          </div>
          <div className="swiper-section__text">{description}</div>
        </div>
      </div>
    ));
  };

  return items?.length > 0 ? (
    <div className="swiper-section" id={id}>
      <h2 className="swiper-section__title">{title}</h2>
      <div className="swiper-section__list">{renderItems()}</div>

      <div className="swiper-section__list-mobile">
        {items && <Swiper {...getSwiperParams()}>{renderItems()}</Swiper>}
      </div>
    </div>
  ) : null;
};
