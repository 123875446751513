import React, { useEffect, useCallback } from 'react';
import { getSwiperParams } from './swiper.config';

import Swiper from 'react-id-swiper/lib/ReactIdSwiper.custom';
import { StrengthsSwiperItem } from '../StrengthsSwiperItem';

import './StrengthsListSwiper.scss';

export const StrengthsListSwiper = ({ title, subtitle, strengths, icons }) => {
  const onWindowResize = useCallback(() => {
    const params = getSwiperParams();
    const slides = document.querySelectorAll('.swiper-slide');
    slides.forEach((slide) => (slide.style.width = params.width + 'px'));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  });

  return (
    <div className="strengths-swiper">
      <div className="strengths-swiper__header">
        <h3 className="strengths-swiper__title">{title}</h3>
        <h2 className="strengths-swiper__subtitle">{subtitle}</h2>
      </div>
      <div className="strengths-swiper__items">
        {strengths && (
          <Swiper {...getSwiperParams()}>
            {strengths.map(({ label, text, iconKey }) => StrengthsSwiperItem({ label, text, icon: icons[iconKey] }))}
          </Swiper>
        )}
      </div>
    </div>
  );
};
