import React, { useState } from 'react';
import * as uuid from 'uuid';
import { geocodeByPlaceId } from 'react-places-autocomplete';
import { useTranslation } from 'utils/hooks';
import { autocompleteService, mapRequest } from './autocomplete-service';

import { AutoComplete } from 'antd';

import './SearchDestination.scss';

const createValue = ({ description, place_id }) => ({ description, place_id });

export const SearchDestination = ({ destination, countryCode, setFieldValue }) => {
  const [options, setOptions] = useState([]);
  const { localize } = useTranslation();

  const suggestionCallback = (suggestion) => {
    const places = suggestion?.map(createValue);
    setOptions(places);
  };

  const searchDestination = (value) => {
    const request = mapRequest(value);
    autocompleteService.getPlacePredictions(request, suggestionCallback);
  };

  const onSelectDestination = async (_, { description, place_id }) => {
    const place = await geocodeByPlaceId(place_id);
    const country_code = place[0]?.address_components?.find(({ types }) => types.includes('country'))?.short_name;

    setFieldValue(destination, description);
    setFieldValue(countryCode, country_code);
  };

  const renderOption = ({ description, place_id }) => (
    <AutoComplete.Option key={uuid.v4()} value={description} description={description} place_id={place_id}>
      <span>{description}</span>
    </AutoComplete.Option>
  );

  return (
    <div className="start-trip__search-destination">
      <AutoComplete
        dataSource={options?.map(renderOption)}
        onSearch={(value) => (value.length >= 3 ? searchDestination(value) : setOptions([]))}
        onSelect={onSelectDestination}
        placeholder={localize('packing.start-trip.type-destination')}
      />
    </div>
  );
};
