import React from 'react';
import * as uuid from 'uuid';

import { StrengthsListSwiper } from '../StrengthsListSwiper';
import { StrengthsItem } from '../StrengthsItem';

import './StrengthsList.scss';

export const StrengthsList = ({ title, subtitle, strengths, icons }) => {
  return (
    <>
      <div className="strengths-list">
        <div className="strengths-list__header">
          <h2 className="strengths-list__title">{title}</h2>
          <h2 className="strengths-list__subtitle">{subtitle}</h2>
        </div>
        <div className="strengths-list__items">
          {strengths.map(({ label, text, iconKey }) => (
            <StrengthsItem key={uuid.v4()} label={label} text={text} icon={icons[iconKey]} />
          ))}
        </div>
      </div>
      <div className="strengths-list-mobile">
        <StrengthsListSwiper title={title} subtitle={subtitle} strengths={strengths} icons={icons} />
      </div>
    </>
  );
};
