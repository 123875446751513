import { takeLatest } from 'redux-saga/effects';
import * as type from './types';
import * as saga from './saga';

export const travelerTypeSagaWatcher = [
  takeLatest(type.START_TEST_REQUEST, saga.startTestWorker),
  takeLatest(type.SEND_ANSWERS_REQUEST, saga.sendAnswersWorker),
  takeLatest(type.FINISH_TEST_REQUEST, saga.finishTestWorker),
  takeLatest(type.GET_RESULT_REQUEST, saga.getResultWorker),
  takeLatest(type.GET_DESTINATIONS_REQUEST, saga.getDestinationsWorker),
  takeLatest(type.RATE_DESTINATION_REQUEST, saga.rateDestinationWorker),
];
