export const SELECT_FRIEND = 'TEST_COMPARISON/SELECT_FRIEND';
export const DESELECT_FRIEND = 'TEST_COMPARISON/DESELECT_FRIEND';
export const SET_AUTHORIZED = 'TEST_COMPARISON/SET_AUTHORIZED';

export const SET_LOADING = 'TEST_COMPARISON/SET_LOADING';

export const GET_FRIENDS_REQUEST = 'TEST_COMPARISON/GET_FRIENDS_REQUEST';
export const SEND_COMPARISON_DATA_REQUEST = 'TEST_COMPARISON/SEND_COMPARISON_DATA_REQUEST';
export const GET_COMPARISON_REQUEST = 'TEST_COMPARISON/GET_COMPARISON_REQUEST';

export const GET_FRIENDS_RESPONSE = 'TEST_COMPARISON/GET_FRIENDS_RESPONSE';
export const SEND_COMPARISON_DATA_RESPONSE = 'TEST_COMPARISON/SEND_COMPARISON_DATA_RESPONSE';
export const GET_COMPARISON_RESPONSE = 'TEST_COMPARISON/GET_COMPARISON_RESPONSE';

export const CLEAN_COMPARISON_DATA_RESPONSE = 'TEST_COMPARISON/CLEAN_COMPARISON_DATA_RESPONSE';
