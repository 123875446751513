import React from 'react';
import { object, string } from 'prop-types';
import { IntlProvider } from 'react-intl';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';

export const LocalizedRouter = ({ appStrings, defaultLanguage, children }) => {
  return (
    <BrowserRouter>
      <Route path="/:lang([a-z]{2})">
        {({ match, location }) => {
          const params = match?.params ?? {};
          const { lang = defaultLanguage } = params;
          const { pathname } = location;

          if (!pathname.includes(`/${lang}/`)) {
            return <Redirect to={`/${lang}/`} />;
          }

          return (
            <IntlProvider locale={lang} messages={appStrings[lang]}>
              {children}
            </IntlProvider>
          );
        }}
      </Route>
    </BrowserRouter>
  );
};

LocalizedRouter.propTypes = {
  appStrings: object.isRequired,
  defaultLanguage: string.isRequired,
};
