import { put, call } from 'redux-saga/effects';
import { usePersonalityTest as testGuid } from 'utils/hooks/traveler-test/usePersonalityTest';
import { AppMessage } from 'const/app-messages';

import * as api from 'api/traveler-test';
import * as actions from './actions';
import * as appAction from 'redux/app/actions';

export function* startTestWorker(action) {
  const { setTestGuid } = testGuid();

  try {
    yield put(actions.setLoading(true));

    const response = yield call(api.startTest, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.startTest(response.data));
        const { personality_test_uuid } = response.data;

        setTestGuid(personality_test_uuid);
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* sendAnswersWorker(action) {
  try {
    yield put(actions.nextPage());
    const response = yield call(api.sendAnswers, action.payload);

    if (response.status !== 200) {
      yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* finishTestWorker(action) {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(api.finishTest, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.finishTestResponse(response));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* getResultWorker(action) {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(api.getResult, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.getResultResponse(response));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* getDestinationsWorker(action) {
  try {
    const response = yield call(api.getDestinations, action.payload);
    yield put(actions.getDestinationsResponse(response.data));
  } catch {
    // error
  }
}

export function* rateDestinationWorker(action) {
  try {
    const response = yield call(api.rateDestination, action.payload);
    if (response.status !== 200) {
      yield put(appAction.toastMessage('error', AppMessage.serverError));
    }

    yield put(actions.rateDestinationResponse(response));
  } catch {
    // error
  }
}
