import { AppStorage } from 'const/app-storage';

export const usePersonalityTest = () => {
  const setTestGuid = (token) => {
    localStorage.setItem(AppStorage.testGuid, token);
  };

  const getTestGuid = () => {
    return localStorage.getItem(AppStorage.testGuid);
  };

  const setFinishedTestGuid = (token) => {
    localStorage.setItem(AppStorage.finishedTestGuid, token);
  };

  const getFinishedTestGuid = () => {
    return localStorage.getItem(AppStorage.finishedTestGuid);
  };

  const checkTestFinished = () => {
    return !!getFinishedTestGuid();
  };

  return { setTestGuid, getTestGuid, setFinishedTestGuid, getFinishedTestGuid, checkTestFinished };
};
