import React from 'react';
import { useTranslation, useAuth, usePersonalityTest, usePersonalityTestResults, useCookie, useMe } from 'utils/hooks';

export const GlobalContext = React.createContext();

export const AppContext = ({ children }) => {
  const { locale } = useTranslation();
  const auth = useAuth();
  const guid = auth.getToken();
  const cookie = useCookie();

  const personalityTest = usePersonalityTest();
  const { personalityResults, refreshPersonalityResults } = usePersonalityTestResults(locale, guid);
  const me = useMe(locale, guid);

  return (
    <GlobalContext.Provider
      value={{
        auth,
        me,
        personalityTest,
        cookie,
        personalityResults,
        refreshPersonalityResults,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
