import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { Space } from 'antd';

import { TravelerPersonality } from '../TravelerPersonality';

import './TravelerProfile.scss';

export const TravelerProfile = ({ travelerProfile }) => {
  const { localize } = useTranslation();

  return (
    <div className="traveler-profile" id="traveler-profile">
      <h2 className="traveler-profile__header">{localize('traveler-test.result.traveler-profile')}</h2>
      <div className="traveler-profile__preferences">
        <Space size={50} direction="vertical" align="center">
          <TravelerPersonality
            leftLabel={localize('traveler-test.result.learning')}
            rightLabel={localize('traveler-test.result.leisure')}
            leftPercent={travelerProfile?.learning_percentage ?? 0}
            rightPercent={travelerProfile?.leisure_percentage ?? 0}
            title={localize('traveler-test.result.destination')}
            toolTip={localize('traveler-test.result.destination.tooltip')}
          />
          <TravelerPersonality
            leftLabel={localize('traveler-test.result.nature')}
            rightLabel={localize('traveler-test.result.city')}
            leftPercent={travelerProfile?.nature_percentage ?? 0}
            rightPercent={travelerProfile?.city_percentage ?? 0}
            title={localize('traveler-test.result.location')}
            toolTip={localize('traveler-test.result.location.tooltip')}
          />
          <TravelerPersonality
            leftLabel={localize('traveler-test.result.risk')}
            rightLabel={localize('traveler-test.result.safety')}
            leftPercent={travelerProfile?.risk_percentage ?? 0}
            rightPercent={travelerProfile?.safety_percentage ?? 0}
            title={localize('traveler-test.result.approach')}
            toolTip={localize('traveler-test.result.approach.tooltip')}
          />
          <TravelerPersonality
            leftLabel={localize('traveler-test.result.accuracy')}
            rightLabel={localize('traveler-test.result.spontaneity')}
            leftPercent={travelerProfile?.accuracy_percentage ?? 0}
            rightPercent={travelerProfile?.spontaneity_percentage ?? 0}
            title={localize('traveler-test.result.planning')}
            toolTip={localize('traveler-test.result.planning.tooltip')}
          />
        </Space>
      </div>
    </div>
  );
};
