import React, { useState, useRef } from 'react';
import { useTranslation } from 'utils/hooks';

import { selectElementText } from 'pages/utils/selectElementText';
import { iOSSafari } from 'pages/utils/detectIOS';

import { Tooltip } from 'antd';
import { FacebookShareButton } from 'react-share';

import './ShareResults.scss';

import FacebookIcon from 'assets/CTA/facebook.svg';
import CopyIcon from 'assets/test-comparison/copy.svg';

export const ShareResults = ({ title, url }) => {
  const linkRef = useRef();
  const { localize } = useTranslation();
  const [tooltip, setTooltip] = useState(localize('traveler-test.comparison.invite.copy-to-clipboard'));

  const onCopy = () => {
    selectElementText(linkRef?.current);
    navigator.clipboard.writeText(url);
    setTooltip(localize('traveler-test.comparison.invite.copied'));
  };

  return (
    <div className="share-result">
      <h3 className="share-result__title">{title}</h3>
      <div className="share-result__link">
        <div className="share-result__url-container">
          <span ref={linkRef} onClick={onCopy} className="share-result__url">
            {url}
          </span>
          {!iOSSafari && (
            <Tooltip placement="top" title={tooltip}>
              <div className="share-result__copy" onClick={onCopy}>
                <img src={CopyIcon} alt="copy" className="share-result__copy-img" />
              </div>
            </Tooltip>
          )}
        </div>
        <FacebookShareButton url={url}>
          <img src={FacebookIcon} alt="facebook" className="share-result__social" />
        </FacebookShareButton>
      </div>
      <img src={FacebookIcon} alt="facebook" className="share-result__social-mobile" />
    </div>
  );
};
