import React, { useEffect, useContext } from 'react';
import { useTranslation, useMultilangRoute } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import * as action from 'redux/traveler-test/actions';
import * as selector from './selectors';
import { icons } from './icons';
import { guest, testCreator, authorizedTestCreator } from './roles';
import { getAnchorConfig } from './anchor.config';
import { AppStorage } from 'const';

import { PageLoading } from 'modules/page-state';
import { Anchor } from 'modules/anchor/components/Anchor';
import { TravelerResultHeader } from './components/TravelerResultHeader';
import { TravelerProfile } from './components/TravelerProfile';
import { TravelerPreference } from './components/TravelerPreference';
import { SwiperSection } from './components/SwiperSection';
import { TravelerSpecializations } from './components/TravelerSpecializations';
import { TravelPersonality } from './components/TravelPersonality';
import { TravelPlanning } from './components/TravelPlanning';
import { ComparePreferences } from '../components/ComparePreferences';
import { Destinations } from './components/Destinations';
import { ComparePreferencesHeader } from './components/ComparePreferencesHeader';

import './TravelerTypeResultPage.scss';
import { GlobalContext } from 'AppContext';

export const TravelerTypeResultPage = () => {
  const { auth, personalityTest, personalityResults, refreshPersonalityResults } = useContext(GlobalContext);
  const params = useParams();

  const { localize, locale } = useTranslation();
  const { AppRoute, redirect, createURL } = useMultilangRoute();
  const anchorConfig = getAnchorConfig(localize);

  const dispatch = useDispatch();
  const result = useSelector(selector.getResult);
  const destinations = useSelector(selector.getDestinations);
  const loading = useSelector(selector.getLoading);
  const authorized = useSelector(selector.getAuthorized);
  const testGuid = params[AppStorage.testGuid];
  const url = createURL(AppRoute.TravelerTestResult, [testGuid], true);
  const lang = locale;
  const guid = auth.getToken();

  const redirectToPersonalityTest = () => {
    redirect(AppRoute.TravelerTest);
  };

  const redirectToTestComparison = () => {
    redirect(AppRoute.TravelerTestCompare);
  };

  const _visitorTypes = [
    testCreator({
      testGuid,
      localize,
      url,
      match:
        !authorized &&
        testGuid === (personalityResults?.personality_test?.uuid ?? personalityTest.getFinishedTestGuid()),
    }),
    guest({
      localize,
      onClick: redirectToPersonalityTest,
      match: testGuid !== (personalityResults?.personality_test?.uuid ?? personalityTest.getFinishedTestGuid()),
    }),
    authorizedTestCreator({
      testGuid,
      localize,
      url,
      onCompareClick: redirectToTestComparison,
      match:
        authorized &&
        testGuid === (personalityResults?.personality_test?.uuid ?? personalityTest.getFinishedTestGuid()),
    }),
  ];

  const _defaultVisitor = _visitorTypes.find(({ role }) => role === 'guest');
  const _visitor = _visitorTypes.find(({ match }) => match) ?? _defaultVisitor;

  useEffect(() => {
    dispatch(action.getResultRequest({ lang, guid, testGuid }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!destinations) return;

    dispatch(action.getDestinationsRequest({ lang, guid }));
  }, [destinations, lang, guid, dispatch]);

  useEffect(() => {
    authorized && refreshPersonalityResults();
  }, [refreshPersonalityResults, authorized]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="traveler-result">
      <TravelerResultHeader
        travelerType={result?.traveler_type}
        skills={result?.skills}
        specializations={result?.specialisations}
        icons={icons}
      />
      <div className="traveler-result__content">
        <Anchor anchorConfig={anchorConfig} />

        <TravelerProfile travelPreferences={result?.travel_preferences} travelerProfile={result?.traveler_profile} />

        {_visitor?.renderShareSection()}

        <TravelerPreference preferences={result?.travel_preferences} />

        <SwiperSection
          id="interesting-facts"
          title={localize('traveler-test.result.interesting-facts')}
          items={result?.insights}
        />

        <TravelerSpecializations specializations={result?.specialisations} />

        <SwiperSection
          id="traveler-skills"
          title={localize('traveler-test.result.traveler-skills')}
          labelWithIcon
          items={result?.skills}
        />

        <TravelPersonality personality={result?.personality} />

        <TravelPlanning personality={result?.personality} />

        <Destinations destinations={destinations} />

        <ComparePreferences renderHeader={ComparePreferencesHeader} />

        {_visitor?.renderCompareSection()}
      </div>
    </div>
  );
};
