import { useHistory } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

export const ScrollToTop = () => {
  const { listen } = useHistory();

  listen(() => {
    scroll.scrollTo({ y: 0, smooth: false });
  });

  return null;
};
