export const en = {
  'routes.traveler-test.landing': '/travel-personality-test',
  'routes.traveler-test.start': '/travel-personality-test/start',
  'routes.traveler-test.invite': '/travel-personality-test/invite/:guid',
  'routes.traveler-test.compare': '/travel-personality-test/compare',
  'routes.traveler-test.comparison-results': '/travel-personality-test/compare/result/:uuid',
  'routes.traveler-test.result': '/travel-personality-test/results/:personality_test_uuid',

  'traveler-test.title': 'Travel personality test - for free',
  'traveler-test.takes-time': 'Takes less than 10 minutes',
  'traveler-test.without-registration': 'No need to register ',
  'traveler-test.next-button': 'Next',
  'traveler-test.title-lowest': 'Disagree',
  'traveler-test.title-highest': 'Agree',

  // Test comparison
  'traveler-test.comparison.compare-profile': 'Compare your profile with your friends',
  'traveler-test.comparison.header.subtitle':
    'The app will assign the tasks for your next journey and show you what connects you and makes you different when traveling.',

  'traveler-test.comparison.invite.add-friends': 'Add friends to compare results',
  'traveler-test.comparison.invite.copy-to-clipboard': 'Copy to clipboard',
  'traveler-test.comparison.invite.copied': 'Copied!',

  'traveler-test.comparison.friends.friends-list': 'Friend list',
  'traveler-test.comparison.friends.name': 'Name',
  'traveler-test.comparison.friends.traveler-type': 'Traveler Type',
  'traveler-test.comparison.friends.add': 'Add',
  'traveler-test.comparison.friends.you': 'You',
  'traveler-test.comparison.friends.comparison': 'Comparison',
  'traveler-test.comparison.friends.compare-results': 'Compare results',

  'traveler-test.comparison.share-comparison': 'Share the comparison',

  'traveler-test.comparison.steps.send-link': 'Send a link to a friend',
  'traveler-test.comparison.steps.ask-friend': 'Ask your friend to fill in the test',
  'traveler-test.comparison.steps.add-friend': 'Refresh your friend list',

  // Test comparison results
  'traveler-test.comparison.results.profiles-comparison': 'Travel Personality Comparison',
  'traveler-test.comparison.results.your-preferences': 'Here are your preferences',
  'traveler-test.comparison.results.spend-time': 'How do you like to spend time',
  'traveler-test.comparison.results.what-environment': 'What environment do you prefer',
  'traveler-test.comparison.results.your-risk-attitude': 'What is your attitude to risk',
  'traveler-test.comparison.results.plan-trips': 'How do you plan your travel',

  'traveler-test.comparison.results.tasks.how-you-handle-tasks': 'How do you handle the tasks',
  'traveler-test.comparison.results.tasks.preferred-location': 'Preferred location',
  'traveler-test.comparison.results.tasks.packing': 'Packing',
  'traveler-test.comparison.results.tasks.accomodation': 'Accommodation',
  'traveler-test.comparison.results.tasks.photographer': 'Photographer',
  'traveler-test.comparison.results.tasks.organiser': 'Organiser',
  'traveler-test.comparison.results.tasks.restaurant_choice': 'Restaurant choice',
  'traveler-test.comparison.results.location.mountains': 'Mountains',
  'traveler-test.comparison.results.location.water': 'Water',
  'traveler-test.comparison.results.location.city': 'City',
  'traveler-test.comparison.results.location.festival': 'Festival',

  'traveler-test.comparison.results.summary.title': 'Summary',
  'traveler-test.comparison.results.summary.subtitle': 'Distribution of tasks',

  'traveler-test.comparison.results.summary.where-should-you-go': 'Where should you go:',

  'traveler-test.comparison.results.see-results': 'See my results',
  'traveler-test.comparison.results.retake-test': 'Retake the test',

  // Traveler test result page
  'traveler-test.result.traveler-specialization': 'Specializations',
  'traveler-test.result.traveler-skills': 'Skills',

  'traveler-test.result.traveler-profile': 'Traveler profile',
  'traveler-test.result.destination': 'Destination',
  'traveler-test.result.destination.tooltip': 'This feature indicates whether you value trying new things or resting',
  'traveler-test.result.location': 'Location',
  'traveler-test.result.location.tooltip': 'This feature describes which environment you feel best in',
  'traveler-test.result.approach': 'Approach',
  'traveler-test.result.approach.tooltip': 'This feature describes your attitude towards taking risks while traveling',
  'traveler-test.result.planning': 'Planning',
  'traveler-test.result.planning.tooltip': 'This feature indicates how you approach planning your trips',

  'traveler-test.result.learning': 'Learning',
  'traveler-test.result.leisure': 'Resting',
  'traveler-test.result.nature': 'Nature',
  'traveler-test.result.city': 'City',
  'traveler-test.result.risk': 'Risk',
  'traveler-test.result.safety': 'Safety',
  'traveler-test.result.accuracy': 'Precisely',
  'traveler-test.result.spontaneity': 'Spontaneously',

  'traveler-test.result.CTA.discover-personality': 'Discover your Travel Personality',
  'traveler-test.result.CTA.take-free-test': 'Take a free test!',
  'traveler-test.result.CTA.understand-preferences': 'Understand your travel preferences!',
  'traveler-test.result.CTA.take-test': 'Take the test',
  'traveler-test.result.CTA.share': 'Share your result',
  'traveler-test.result.CTA.save-test':
    'Create a free account to save your results and compare them with your friends!',
  'traveler-test.result.CTA.signup-subtitle': 'What does having a MyLuggage account give you?',
  'traveler-test.result.CTA.easy-access': 'Easy access to your account from any device',
  'traveler-test.result.CTA.save-packing': 'Save your packing lists',
  'traveler-test.result.CTA.compare-with-friends': 'Compare your results with friends',
  'traveler-test.result.CTA.sign-up-google': 'Sign Up with Google',
  'traveler-test.result.CTA.sign-up-facebook': 'Sign Up with Facebook',
  'traveler-test.result.CTA.sign-up-classic': 'Classic Registration',
  'traveler-test.result.CTA.already-have-account': 'Already have an account?',
  'traveler-test.result.CTA.login': 'Log in',

  'traveler-test.result.traveler-preference': 'Traveler preferences',
  'traveler-test.result.interesting-facts': 'Interesting facts',
  'traveler-test.result.traveler-specializations': 'Specializations',

  'traveler-test.result.travel-personality': 'Travel personality',

  'traveler-test.result.travel-planning': 'Planning',
  'traveler-test.result.collecting-information': 'Collecting information',
  'traveler-test.result.innovator': 'Innovator',
  'traveler-test.result.clear-planner': 'Planner',

  'traveler-test.result.compare-preferences': 'Compare your preferences with others',
  'traveler-test.result.send-survey-to-people': 'Send survey to selected people',
  'traveler-test.result.compare-your-results': 'Compare your results',
  'traveler-test.result.discover-differences-between-you': 'Discover the differences between you',
  'traveler-test.result.how-you-plan-trip': 'How do you plan your travel',
  'traveler-test.result.how-you-spend-time': 'How do you like to spend time',

  'traveler-test.result.destinations.recommended-destination': 'Recommended destinations',
  'traveler-test.result.destinations.learn-more': 'Learn more',
  'traveler-test.result.destinations.like': 'Like',
  'traveler-test.result.destinations.dislike': 'Dislike',
  'traveler-test.result.destinations.plan-trip': 'Plan the Trip',

  'traveler-test.result.compare-preferences-button': 'Compare results',

  //Traveler test landing page
  'traveler-test.landing.understand-preferences': 'Understand your travel preferences',
  'traveler-test.landing.find-out-what-enjoy': 'Find out what you enjoy and what you should avoid',
  'traveler-test.landing.how-spend-time': 'How do you like to spend time?',
  'traveler-test.landing.what-environment': 'What environment do you prefer?',
  'traveler-test.landing.how-risky': 'How risky are you?',
  'traveler-test.landing.how-you-plan-travel': 'How do you plan your travel?',
  'traveler-test.landing.take-free-test': 'Take a free test!',
  'traveler-test.landing.go-to-test': 'Go to the test!',
  'traveler-test.landing.take-test': 'Take the test!',

  'traveler-test.landing.know-strengths': 'Know your strengths!',
  'traveler-test.landing.how-get-most-of-trip': 'Find out how you can get the most out of your trip',
  'traveler-test.landing.activities-that-pleasant': 'Activities that are pleasant to you:',
  'traveler-test.landing.specializations': 'Specializations',
  'traveler-test.landing.surprise-fan': 'Surprises',
  'traveler-test.landing.bargain-hunter': 'Bargain Hunter',
  'traveler-test.landing.culture-fan': 'Culture Fan',
  'traveler-test.landing.organizer': 'Organiser',
  'traveler-test.landing.born-planner': 'Planner',
  'traveler-test.landing.photographer': 'Photographer',
  'traveler-test.landing.accommodation': 'Accommodation',
  'traveler-test.landing.talents-that-built-travel': 'Talents that build your travels:',

  'traveler-test.landing.surprise-fan.text':
    'Your inventiveness and creativity are irreplaceable while traveling. You are the master of surprises and you are happy to organize such nice gestures for friends or loved ones.',
  'traveler-test.landing.bargain-hunter.text':
    'You know that planning your trip allows you to take advantage of attractive offers and bargains. You have a gift of choosing offers with a good price-quality ratio.',
  'traveler-test.landing.culture-fan.text':
    'You are eager to choose travel destinations that abound in a variety of attractions that can teach you something new, or through which you can see interesting monuments and historical objects.',
  'traveler-test.landing.organizer.text':
    "You are often responsible for organization, but it's not a problem for you. What's more, you enjoy it.",
  'traveler-test.landing.born-planner.text':
    'You have a feature that other travelers can envy - you can easily organize and plan all the most important aspects of your trip.',
  'traveler-test.landing.photographer.text':
    'Thanks to you the likes, hearts and comments explode after the journey! You want to capture as much as possible of your trip so that you can enjoy the views and memories.',

  'traveler-test.landing.compare-preferences': 'Compare your preferences with others',
  'traveler-test.landing.use-results-comparison':
    'Use the results comparison function and make group trips even better than before',

  'traveler-test.landing.share-tasks': 'Share tasks for the next trip',
  'traveler-test.landing.see-who-should-take-care':
    'See who should take care of booking accommodation and who will prepare a packing list.',
  'traveler-test.landing.packing': 'Packing',
  'traveler-test.landing.accommodation-booking': 'Accommodation booking',
  'traveler-test.landing.taking-photos': 'Taking photos',
};
