import React from 'react';
import {TravelPreferences} from '../TravelPreferences';

import './LandingHeader.scss';

export const LandingHeader = () => {

    return (
        <div className="understand-preferences">
            <div className="understand-preferences__overview">
                <TravelPreferences/>
            </div>
        </div>
    );
};
