import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { ComparePreferences } from 'pages/apps/personality-test/components/ComparePreferences';

import './LandingComparePreferences.scss';

export const LandingComparePreferences = () => {
  const { localize } = useTranslation();

  const renderHeader = () => (
    <div className="landing-compare-preferences__header">
      <h3 className="landing-compare-preferences__title">{localize('traveler-test.landing.compare-preferences')}</h3>
      <h3 className="landing-compare-preferences__subtitle">
        {localize('traveler-test.landing.use-results-comparison')}
      </h3>
    </div>
  );

  return (
    <div className="landing-compare-preferences">
      <ComparePreferences renderHeader={renderHeader} />
    </div>
  );
};
