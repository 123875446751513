import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks';

import './ComparisonSummary.scss';

export const ComparisonSummary = ({ profiles, summary, destination }) => {
  const { localize } = useTranslation();

  const renderSummary = ([key, value]) => {
    const { name, color } = profiles.find(({ uuid }) => uuid === value);

    return (
      <div key={uuid.v4()} className="comparison-summary__profile">
        <div className="comparison-summary__label">{localize(`traveler-test.comparison.results.tasks.${key}`)}</div>
        <div className="comparison-summary__username">
          <div className="comparison-summary__sign" style={{ backgroundColor: color }}>
            <span>{name?.charAt(0).toUpperCase()}</span>
          </div>
          <span className="comparison-summary__name">{name}</span>
        </div>
      </div>
    );
  };

  const renderTip = (text) => (
    <div key={uuid.v4()} className="comparison-summary__tip">
      <p className="comparison-summary__tip-text">{text}</p>
    </div>
  );

  return (
    <div className="comparison-summary">
      <div className="comparison-summary__header">
        <h2 className="comparison-summary__title">{localize('traveler-test.comparison.results.summary.title')}</h2>
        <h3 className="comparison-summary__subtitle">
          {localize('traveler-test.comparison.results.summary.subtitle')}
        </h3>
      </div>
      <div className="comparison-summary__profiles-result">
        {summary && Object.entries(summary?.assigned_tasks).map(renderSummary)}
      </div>
      <div className="comparison-summary__tips">{summary && summary.tips?.map(renderTip)}</div>
      <div className="comparison-summary__destination destination">
        <h2 className="destination__label">
          {localize('traveler-test.comparison.results.summary.where-should-you-go')}
        </h2>
        <div className="destination__location">
          {destination && localize(`traveler-test.comparison.results.location.${destination?.key}`)}!
        </div>
      </div>
    </div>
  );
};
