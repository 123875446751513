import React from 'react';

import { Button } from 'antd';

import './ResultsCTA.scss';

export const ResultsCTA = ({ title, titleFontSize = 45, buttonText, onClick }) => {
  return (
    <div className="traveler-result__CTA CTA">
      <h2 className="CTA__title" style={{ fontSize: titleFontSize }}>
        {title}
      </h2>
      <div className="CTA__button">
        <Button type="primary" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
