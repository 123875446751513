import { useTranslation } from 'utils/hooks/app/useTranslation';
import { AppRoute } from 'const';

// import PhoneIcon from 'assets/my-profile/phone.svg';
// import MyTripsIcon from 'assets/my-profile/my-trips.svg';
import TravelerTypeIcon from 'assets/my-profile/traveler-type.svg';
import CompareIcon from 'assets/my-profile/compare.svg';
import GuidesIcon from 'assets/my-profile/guides.svg';
import {useMultilangRoute} from "../../../../../../utils/hooks/app";

const createNavItem = (icon, text, onclick) => ({ icon, text, onclick });

export const useNavItems = (testGuid) => {
  const { localize } = useTranslation();
  const { redirect } = useMultilangRoute();

  const getTestResultInfo = () => {
    const localizeKey = !!testGuid
      ? 'my-profile.navigation.my-traveler-type.test-finished'
      : 'my-profile.navigation.my-traveler-type.test-unfinished';

    const link = !!testGuid ? AppRoute.TravelerTestResult : AppRoute.TravelerTest;
    const param = testGuid;

    return { localizeKey, location: { link, param } };
  };

  const getCompareTestInfo = () => {
    const localizeKey = 'my-profile.navigation.compare-with-friends';
    const link = !!testGuid ? AppRoute.TravelerTestCompare : AppRoute.TravelerTest;

    return { localizeKey, location: { link } };
  };

  const geTravelGuidesInfo = () => {
    const localizeKey = 'my-profile.navigation.my-travel-guides';

    return { localizeKey };
  };

  const testResult = getTestResultInfo();
  const personalityTest = getCompareTestInfo();
  const travelGuides = geTravelGuidesInfo();

  const travelerTypeOnClick = () => {
    testResult.location && redirect(testResult.location.link, [testResult.location.param]);
  };

  const compareOnClick = () => {
    personalityTest.location && redirect(personalityTest.location.link, [personalityTest.location.param]);
  };

  const travelGuidesOnClick = () => {
    window.location = "./travel-guides";
  };

  const navItems = [
    // createNavItem(PhoneIcon, localize('my-profile.navigation.pack-for-trip')),
    // createNavItem(MyTripsIcon, localize('my-profile.navigation.your-travels')),
    createNavItem(TravelerTypeIcon, localize(testResult.localizeKey), travelerTypeOnClick),
    createNavItem(CompareIcon, localize(personalityTest.localizeKey), compareOnClick),
    createNavItem(GuidesIcon, localize(travelGuides.localizeKey), travelGuidesOnClick),
  ];

  return { navItems };
};
