import React, { useState, useRef, useContext } from 'react';
import { AppRoute } from 'const';
import { useTranslation, useMultilangRoute } from 'utils/hooks';
import { selectElementText } from 'pages/utils/selectElementText';
import { GlobalContext } from 'AppContext';
import { iOSSafari } from 'pages/utils/detectIOS';

import { Tooltip } from 'antd';
import { UserSteps } from 'pages/apps/personality-test/components/UserSteps';

import './TestComparisonInvite.scss';
import CopyIcon from 'assets/test-comparison/copy.svg';

export const TestComparisonInvite = ({ user }) => {
  const { personalityTest } = useContext(GlobalContext);
  const linkRef = useRef();
  const { localize } = useTranslation();
  const { createURL } = useMultilangRoute();
  const [tooltip, setTooltip] = useState(localize('traveler-test.comparison.invite.copy-to-clipboard'));

  const url = createURL(
    AppRoute.TravelerInvite,
    [user?.id, user?.personality_test?.uuid ?? personalityTest.getFinishedTestGuid()],
    true
  );

  const steps = [
    localize('traveler-test.comparison.steps.send-link'),
    localize('traveler-test.comparison.steps.ask-friend'),
    localize('traveler-test.comparison.steps.add-friend'),
  ];

  const onCopy = () => {
    selectElementText(linkRef?.current);
    if (!iOSSafari) {
      navigator.clipboard.writeText(url);
      setTooltip(localize('traveler-test.comparison.invite.copied'));
    }
  };

  return (
    <div className="comparison-invite">
      <h3 className="comparison-invite__title">{localize('traveler-test.comparison.invite.add-friends')}</h3>
      <UserSteps steps={steps} />
      <div className="comparison-invite__link">
        <span ref={linkRef} onClick={onCopy} className="comparison-invite__url">
          {url}
        </span>
        {!iOSSafari && (
          <Tooltip placement="top" title={tooltip}>
            <div id="copy-to-clipboard-button" onClick={onCopy} className="comparison-invite__copy">
              <img src={CopyIcon} alt="copy" className="comparison-invite__copy-img" />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
