import React from 'react';

import './Banner.scss';

export const Banner = ({ image, mobileImage }) => {
  return (
    <div className="banner">
      <img src={image} alt="banner" className="banner__desktop" />
      <img src={mobileImage} alt="banner" className="banner__mobile" />
    </div>
  );
};
