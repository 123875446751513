import { put, call } from 'redux-saga/effects';
import { AppMessage } from 'const';

import * as comparisonApi from 'api/test-comparison';
import * as api from 'api/user';
import * as actions from './actions';
import * as appActions from 'redux/app/actions';

export function* getPersonalityResultsWorker(action) {
  try {
    const response = yield call(comparisonApi.getFriends, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.personalityResultsResponse(response.data));
        yield put(actions.setAuthorized(true));
        break;

      case 401:
        yield put(actions.setAuthorized(false));
        break;

      default:
        break;
    }
  } catch {
    // error handling
  }
}

export function* getMeWorker(action) {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(api.getMe, action.payload);

    yield put(actions.getMeResponse(response.data));
    yield put(actions.setLoading(false));
  } catch {
    // error handling
  }
}

export function* updateMeWorker(action) {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(api.updateMe, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.updateMeResponse(response));
        yield put(appActions.toastMessage('success', AppMessage.profileUpdated));
        break;

      default:
        yield put(appActions.toastMessage('error', AppMessage.profileUpdateFail));
    }

    yield put(actions.setLoading(false));
  } catch {
    // error handling
  }
}

export function* deleteMeWorker(action) {
  try {
    yield put(actions.setLoading(true));
    const response = yield call(api.deleteMe, action.payload);
    yield put(actions.deleteMeResponse(response));

    switch (response.status) {
      case 200:
        yield put(actions.deleteMeResponse(response));
        break;

      case 402:
        yield put(appActions.toastMessage('error', AppMessage.profileDeleteFail));
        break;

      default:
        break;
    }
    yield put(actions.setLoading(false));
  } catch {
    // error
  }
}
