import { startTripValidator } from '../validators/start-trip.validator';

export const fields = {
  destination: 'destination',
  countryCode: 'countryCode',
  startDate: 'startDate',
  endDate: 'endDate',
  gender: 'gender',
};

export const startTripSchema = (initialValues) => ({
  initialValues: {
    [fields.destination]: initialValues?.destination ?? '',
    [fields.countryCode]: initialValues?.countryCode ?? '',
    [fields.startDate]: initialValues?.startDate ?? null,
    [fields.endDate]: initialValues?.endDate ?? null,
    [fields.gender]: initialValues?.gender ?? '',
  },
  validationSchema: startTripValidator,
  displayName: 'startTripForm',
});
