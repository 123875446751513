import React, { useState, useContext, useEffect } from 'react';
import * as uuid from 'uuid';
import { useDispatch } from 'react-redux';
import { useTranslation, useMultilangRoute } from 'utils/hooks';

import { Modal, Button } from 'antd';

import * as action from 'redux/traveler-test/actions';
import { GlobalContext } from 'AppContext';

import './DestinationModal.scss';
import RateIcon from 'assets/traveler-type-result/rate.svg';

const rates = {
  like: 'like',
  dislike: 'dislike',
};

export const DestinationModal = ({ visible, setVisible, destination }) => {
  const { auth } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const { localize } = useTranslation();
  const { forceRedirect, AppRoute } = useMultilangRoute();
  const images = [destination?.image];

  const [heroImage, setHeroImage] = useState(0);
  const guid = auth.getToken();

  useEffect(() => {
    if (!visible) setHeroImage(0);
  }, [visible, setHeroImage]);

  const onChangeActiveImage = (idx) => {
    setHeroImage(idx);
  };

  const onSetRate = (rate) => {
    if (rate === destination?.rate) return;

    const id = destination?.id;

    dispatch(action.rateDestinationRequest({ id, rate, guid }));
    dispatch(action.rateDestination({ id, rate }));
  };

  const onPlanTrip = () => {
    forceRedirect(AppRoute.Home);
  };

  const renderImage = (src, idx) => {
    return (
      <div
        data-hero={idx === heroImage}
        key={uuid.v4()}
        onClick={() => onChangeActiveImage(idx)}
        className="destination-modal__picture-wrapper">
        <img src={src} alt="destination" className="destination-modal__picture" />
      </div>
    );
  };

  const renderRate = (rate) => {
    return (
      <div
        onClick={() => onSetRate(rate)}
        className={`destination-info__rate rate-${rate}`}
        data-clicked={destination?.rate === rate}
        data-event="destination_rate"
        data-rate={rate}
        data-destination-id={destination?.id}
        data-destination-name={destination?.name}>
        <img src={RateIcon} alt="rate" className="destination-info__rate-img" />
        <span className="destination-info__rate-text">{localize(`traveler-test.result.destinations.${rate}`)}</span>
      </div>
    );
  };

  return (
    <Modal
      width="auto"
      wrapClassName="destination-modal"
      visible={visible}
      onCancel={() => setVisible(false)}
      centered
      footer={null}
      destroyOnClose>
      <div className="destination-modal__content">
        <div className="destination-modal__pictures">
          {renderImage(images[heroImage], heroImage)}
          {images.map(renderImage)}
        </div>
        <div className="destination-modal__destination-info destination-info">
          <div className="destination-info__rates">
            {renderRate(rates.dislike)}
            {renderRate(rates.like)}
          </div>
          <h3 className="destination-info__name">{destination?.name}</h3>
          <p className="destination-info__description">{destination?.description}</p>
          <Button
            id="destination-plan-trip"
            data-destination-id={destination?.id}
            data-destination-name={destination?.name}
            onClick={onPlanTrip}
            type="primary">
            {localize('traveler-test.result.destinations.plan-trip')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
