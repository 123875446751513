import React from 'react';
import { Progress } from 'antd';

import './PreferenceItem.scss';

export const PreferenceItem = ({ title, leftLabel, rightLabel, leftPercent, rightPercent }) => {
  const renderProgress = () => {
    const reversed = rightPercent > leftPercent;
    const percent = reversed ? rightPercent : leftPercent;
    const className = `preference-item__progress-bar ${reversed ? 'preference-item__progress-bar--reverse' : ''}`;

    return (
      <div className={className}>
        <Progress trailColor="#d8d8d8" percent={percent} strokeColor="#f9785c" showInfo={false} />
      </div>
    );
  };

  return (
    <div className="preference-item">
      <div className="preference-item__header">
        <h2 className="preference-item__title">{title}</h2>
      </div>
      <div className="preference-item__description">
        <span className="preference-item__label" data-position="left">
          {leftLabel}
        </span>
        <span className="preference-item__percent" data-position="left">
          {leftPercent}%
        </span>
        {renderProgress()}
        <span className="preference-item__percent" data-position="right">
          {rightPercent}%
        </span>
        <span className="preference-item__label" data-position="right">
          {rightLabel}
        </span>
      </div>
      <div className="preference-item__labels-mobile">
        <span className="preference-item__label-mobile">{leftLabel}</span>
        <span className="preference-item__label-mobile">{rightLabel}</span>
      </div>
    </div>
  );
};
