export const selectElementText = (el) => {
  if (!el) return;

  const selection = window.getSelection();
  const range = document.createRange();

  range.selectNodeContents(el);
  selection.removeAllRanges();
  selection.addRange(range);
};
