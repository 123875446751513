import React from 'react';
import { Progress } from 'antd';

import './TravelerPersonality.scss';
import { QuestionMark } from 'assets/components/QuestionMark';

export const TravelerPersonality = ({ leftLabel, rightLabel, leftPercent, rightPercent, title, toolTip }) => {
  const renderProgress = () => {
    const reversed = rightPercent > leftPercent;
    const percent = reversed ? rightPercent : leftPercent;
    const className = `traveler-personality__progress-bar ${
      reversed ? 'traveler-personality__progress-bar--reverse' : ''
    }`;

    return (
      <Progress trailColor="#d8d8d8" percent={percent} className={className} strokeColor="#f9785c" showInfo={false} />
    );
  };

  return (
    <div className="traveler-personality">
      <div className="traveler-personality__header">
        <h2 className="traveler-personality__title">{title}</h2>
        <QuestionMark fill="#dbdbdb" className="traveler-personality__question-mark" />
        <div className="traveler-personality__tooltip" dangerouslySetInnerHTML={{ __html: toolTip }} />
      </div>
      <div className="traveler-personality__description">
        <span className="traveler-personality__label" data-position="left">
          {leftLabel}
        </span>
        <span className="traveler-personality__percent" data-position="left">
          {`${leftPercent}%`}
        </span>
        {renderProgress()}
        <span className="traveler-personality__percent" data-position="right">
          {`${rightPercent}%`}
        </span>
        <span className="traveler-personality__label" data-position="right">
          {rightLabel}
        </span>
      </div>
      <div className="traveler-personality__labels-mobile">
        <span className="traveler-personality__label-mobile">{leftLabel}</span>
        <span className="traveler-personality__label-mobile">{rightLabel}</span>
      </div>
    </div>
  );
};
