import React from 'react';

export const QuestionMark = ({ fill, className }) => {
  return (
    <svg className={className} width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 17.3379C11.5933 17.3379 12.0742 16.8569 12.0742 16.2637C12.0742 15.6704 11.5933 15.1895 11 15.1895C10.4067 15.1895 9.92578 15.6704 9.92578 16.2637C9.92578 16.8569 10.4067 17.3379 11 17.3379Z"
        fill={fill}
      />
      <path
        d="M11 0C4.92061 0 0 4.91979 0 11C0 17.0794 4.91979 22 11 22C17.0794 22 22 17.0802 22 11C22 4.92061 17.0802 0 11 0ZM11 20.2812C5.87052 20.2812 1.71875 16.1302 1.71875 11C1.71875 5.87052 5.86983 1.71875 11 1.71875C16.1295 1.71875 20.2812 5.86983 20.2812 11C20.2812 16.1295 16.1302 20.2812 11 20.2812Z"
        fill={fill}
      />
      <path
        d="M11 5.52148C9.10456 5.52148 7.5625 7.06355 7.5625 8.95898C7.5625 9.43362 7.94724 9.81836 8.42188 9.81836C8.89651 9.81836 9.28125 9.43362 9.28125 8.95898C9.28125 8.01127 10.0523 7.24023 11 7.24023C11.9477 7.24023 12.7188 8.01127 12.7188 8.95898C12.7188 9.9067 11.9477 10.6777 11 10.6777C10.5254 10.6777 10.1406 11.0625 10.1406 11.5371V13.6855C10.1406 14.1602 10.5254 14.5449 11 14.5449C11.4746 14.5449 11.8594 14.1602 11.8594 13.6855V12.2877C13.3403 11.9052 14.4375 10.5578 14.4375 8.95898C14.4375 7.06355 12.8954 5.52148 11 5.52148Z"
        fill={fill}
      />
    </svg>
  );
};
