import * as type from './types';

const initialState = {
  questions: [],
  answers: [],
  page: 1,
  progress: 0,
  loading: true,
  destinations: null,

  getAllQuestionsResponse: {},
  sendAnswersResponse: {},
  finishTestResponse: {},
  resultTestResponse: {},
};

export const travelerTest = (state = initialState, action) => {
  switch (action.type) {
    case type.START_TEST:
      return {
        ...state,
        questions: action.payload.questions,
      };

    case type.SET_ANSWERS:
      return {
        ...state,
        answers: action.payload,
      };

    case type.SET_ANSWER:
      const { id, answer } = action.payload;

      return {
        ...state,
        answers: state.answers.map((a) => {
          a.answer = a.id === id ? answer : a.answer;
          return a;
        }),
      };

    case type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case type.SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    case type.NEXT_PAGE:
      return {
        ...state,
        page: ++state.page,
      };

    case type.RATE_DESTINATION: {
      const { id, rate } = action.payload;

      return {
        ...state,
        destinations: state.destinations.map((destination) => {
          if (destination.id === id) destination.rate = rate;
          return destination;
        }),
      };
    }

    case type.START_TEST_REQUEST:
      return state;

    case type.SEND_ANSWERS_REQUEST:
      return state;

    case type.FINISH_TEST_REQUEST:
      return state;

    case type.GET_RESULT_REQUEST:
      return state;

    case type.GET_DESTINATIONS_REQUEST:
      return state;

    case type.RATE_DESTINATION_REQUEST:
      return state;

    case type.SEND_ANSWERS_RESPONSE:
      return {
        ...state,
        sendAnswersResponse: action.payload,
      };

    case type.FINISH_TEST_RESPONSE:
      return {
        ...state,
        finishTestResponse: action.payload,
      };

    case type.GET_RESULT_RESPONSE:
      return {
        ...state,
        resultTestResponse: action.payload,
      };

    case type.GET_DESTINATIONS_RESPONSE:
      return {
        ...state,
        destinations: action.payload,
      };

    case type.RATE_DESTINATION_RESPONSE:
      return {
        ...state,
        rateDestinationResponse: action.payload,
      };

    default:
      return state;
  }
};
