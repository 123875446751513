import React, { useRef } from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { Banner } from '../Banner';
import SlideToggle from 'react-slide-toggle';

import './TravelerPreference.scss';

import TravelPreferencesBanner from 'assets/traveler-type-result/banners/travel-preferences.png';
import TravelPreferencesMobileBanner from 'assets/traveler-type-result/banners/travel-preferences.mobile.png';
import Arrow from 'assets/caret-down.svg';

export const TravelerPreference = ({ preferences }) => {
  const { localize } = useTranslation();
  const toggleRef = useRef();
  const text = preferences && Object.values(preferences);

  const onExpanded = () => {
    toggleRef.current.classList.add('traveler-preference__toggle-button-hidden');
  };

  return (
    <div className="traveler-preference" id="traveler-preference">
      <Banner image={TravelPreferencesBanner} mobileImage={TravelPreferencesMobileBanner} />
      <h2 className="traveler-preference__title">{localize('traveler-test.result.traveler-preference')}</h2>
      <div
        className="traveler-preference__text"
        dangerouslySetInnerHTML={{
          __html: preferences && text[0],
        }}
      />
      <SlideToggle
        onExpanded={onExpanded}
        duration={250}
        collapsed
        render={({ toggle, setCollapsibleElement }) => (
          <>
            <div
              ref={setCollapsibleElement}
              className="traveler-preference__text"
              dangerouslySetInnerHTML={{
                __html: preferences && text.slice(1).join(''),
              }}
            />
            <div
              id="traveler-preferences-expand"
              ref={toggleRef}
              onClick={toggle}
              className="traveler-preference__toggle-button">
              <img src={Arrow} alt="expand" className="traveler-preference__expand-icon" />
              <span className="traveler-preference__expand-text">{localize('app.page.content.expand')}</span>
            </div>
          </>
        )}
      />
    </div>
  );
};
