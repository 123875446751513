import React from 'react';
import * as uuid from 'uuid';

import { Progress } from 'antd';

import './ComparePreferencesOverview.scss';

export const ComparePreferencesOverview = ({
  title,
  users,
  leftLabel,
  rightLabel,
  leftPercentKey,
  rightPercentKey,
  border = null,
}) => {
  const getPercent = (user) => {
    return { leftPercent: user.traveler_profile[leftPercentKey], rightPercent: user.traveler_profile[rightPercentKey] };
  };

  const renderProgress = (color, leftPercent, rightPercent) => {
    const reversed = rightPercent > leftPercent;
    const percent = reversed ? rightPercent : leftPercent;
    const className = `comparison__progress ${reversed ? 'comparison__progress--reverse' : ''}`;

    return (
      <div className={className}>
        <Progress trailColor="#d8d8d8" percent={percent} strokeColor={color} showInfo={false} />
      </div>
    );
  };

  const renderData = (user) => {
    const { leftPercent, rightPercent } = getPercent(user);

    return (
      <div key={uuid.v4()} className="comparison__data">
        <div className="comparison__user">
          <div className="comparison__username">{user.name}</div>
          <div className="comparison__bullet" style={{ backgroundColor: user.color }} />
        </div>
        <div className="comparison__percent">{leftPercent}%</div>
        {renderProgress(user.color, leftPercent, rightPercent)}
        <div className="comparison__percent">{rightPercent}%</div>
      </div>
    );
  };

  return (
    <div className="compare-overview">
      <h2 className="compare-overview__title">{title}</h2>
      <div className="compare-overview__comparison comparison" style={{ border }}>
        <div className="comparison__header">
          <div className="comparison__label">{leftLabel}</div>
          <div className="comparison__label">{rightLabel}</div>
        </div>
        {users && users?.map((user) => renderData(user))}
      </div>
      <div className="comparison__users-mobile">
        {users &&
          users?.map(({ color, name }) => (
            <div key={uuid.v4()} className="comparison__user-mobile">
              <div className="comparison__username">{name}</div>
              <div className="comparison__bullet" style={{ backgroundColor: color }} />
            </div>
          ))}
      </div>
    </div>
  );
};
