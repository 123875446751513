import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { PreferenceItem } from '../PreferenceItem';

import './TravelPreferences.scss';

export const TravelPreferences = () => {
  const { localize } = useTranslation();

  return (
    <div className="travel-preferences">
      <PreferenceItem
        title={localize('traveler-test.landing.how-spend-time')}
        leftLabel={localize('traveler-test.result.learning')}
        rightLabel={localize('traveler-test.result.leisure')}
        leftPercent={40}
        rightPercent={60}
      />
      <PreferenceItem
        title={localize('traveler-test.landing.what-environment')}
        leftLabel={localize('traveler-test.result.nature')}
        rightLabel={localize('traveler-test.result.city')}
        leftPercent={62}
        rightPercent={38}
      />
      <PreferenceItem
        title={localize('traveler-test.landing.how-risky')}
        leftLabel={localize('traveler-test.result.risk')}
        rightLabel={localize('traveler-test.result.safety')}
        leftPercent={27}
        rightPercent={73}
      />
      <PreferenceItem
        title={localize('traveler-test.landing.how-you-plan-travel')}
        leftLabel={localize('traveler-test.result.accuracy')}
        rightLabel={localize('traveler-test.result.spontaneity')}
        leftPercent={70}
        rightPercent={30}
      />
    </div>
  );
};
