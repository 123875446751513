export const AppRoute = {
  Home: 'routes.home',
  TravelerTestLanding: 'routes.traveler-test.landing',
  TravelerTest: 'routes.traveler-test.start',
  TravelerInvite: 'routes.traveler-test.invite',
  TravelerTestResult: 'routes.traveler-test.result',
  TravelerTestCompare: 'routes.traveler-test.compare',
  TravelerTestComparisonResult: 'routes.traveler-test.comparison-results',

  StartTrip: 'routes.packing.start-trip',

  Landing: 'routes.my-profile',
  Login: 'routes.login',
  SignUp: 'routes.sign-up',
  MyProfile: 'routes.my-profile',
  ProfileSettings: 'routes.profile-settings',
};
