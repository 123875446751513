import React from 'react';
import { ShareResults } from 'pages/apps/personality-test/components/ShareResult';
import { Button } from 'pages/apps/general/components/Button';

export const authorizedTestCreator = ({ testGuid, localize, onCompareClick, url, match }) => {
  const role = 'authorizedTestCreator';

  const renderShareSection = () => (
    <ShareResults title={localize('traveler-test.result.CTA.share')} url={url} uuid={testGuid} />
  );

  const renderCompareSection = () => (
    <div className="traveler-result__compare-button">
      <Button
        id="compare-results-button"
        type="primary"
        text={localize('traveler-test.result.compare-preferences-button')}
        clickHandler={onCompareClick}
      />
    </div>
  );

  return { role, renderShareSection, renderCompareSection, match };
};
