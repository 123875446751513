export const SET_LOADING = 'USER/SET_LOADING';

export const PERSONALITY_RESULTS_REQUEST = 'USER/PERSONALITY_RESULTS_REQUEST';
export const PERSONALITY_RESULTS_RESPONSE = 'USER/PERSONALITY_RESULTS_RESPONSE';
export const SET_AUTHORIZED = 'USER/SET_AUTHORIZED';

export const GET_ME_REQUEST = 'USER/GET_ME_REQUEST';
export const GET_ME_RESPONSE = 'USER/GET_ME_RESPONSE';

export const UPDATE_ME_REQUEST = 'USER/UPDATE_ME_REQUEST';
export const UPDATE_ME_RESPONSE = 'USER/UPDATE_ME_RESPONSE';

export const DELETE_ME_REQUEST = 'USER/DELETE_ME_REQUEST';
export const DELETE_ME_RESPONSE = 'USER/DELETE_ME_RESPONSE';
