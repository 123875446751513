// There will be English translation strings
export const en = {
  'routes.home': '/',
  'routes.login': '/login',
  'routes.sign-up': '/signup',
  'routes.my-profile': '/my-profile',
  'routes.profile-settings': '/my-profile/settings',

  // App
  'app.get-app.get-ready': 'Get ready to travel with our free application',
  'app.get-app.personalized-list': 'Personalized list of things to pack',
  'app.get-app.todo-list': 'To-do list before leaving',
  'app.get-app.trusted-destination': 'Trusted destination information',
  'app.get-app.plan-trip': 'Plan your trip',
  'app.get-app.or': 'or',

  // Toast messages
  'app.toast.server-error': 'Unable to reach the sever. Please try again later.',
  'app.toast.profile.updated': 'Your profile has been updated',
  'app.toast.profile.update.failed': 'Something went wrong',
  'app.toast.profile.delete.failed': 'Something went wrong',

  // My profile
  'my-profile.greeting': 'Hi',
  'my-profile.settings': 'Settings',
  'my-profile.navigation.pack-for-trip': 'Pack up for the trip',
  'my-profile.navigation.your-travels': 'Your trips',
  'my-profile.navigation.my-traveler-type.test-finished': 'Traveler Type - Results',
  'my-profile.navigation.my-traveler-type.test-unfinished': 'Discover your Traveler Type',
  'my-profile.navigation.compare-with-friends': 'Compare traveler type with friends',
  'my-profile.navigation.my-travel-guides': 'My travel guides',

  // Profile settings
  'my-profile.settings.back': 'Back',
  'my-profile.settings.name': 'Name',
  'my-profile.settings.email': 'Email address',
  'my-profile.settings.newsletter': 'Newsletter MyLuggage',
  'my-profile.settings.newsletter-text': 'I would like to receive marketing information from MyLuggage.',
  'my-profile.settings.change-password': 'Change password',
  'my-profile.settings.current-password': 'Current password',
  'my-profile.settings.new-password': 'New password',
  'my-profile.settings.repeat-new-password': 'Repeat new password',
  'my-profile.settings.save-changes': 'Save changes',
  'my-profile.settings.delete-account': 'Delete account',
  'my-profile.settings.delete-account.confirmation': 'Are you sure that you want to delete your account?',
  'my-profile.settings.delete-account.yes': 'Yes, delete',
  'my-profile.settings.delete-account.cancel': 'No, cancel',

  'my-profile.settings.password.required': 'Current password is required',
  'my-profile.settings.new_password.required': 'New password is required',
  'my-profile.settings.new_password_confirmation.required': 'Repeat your new password',
  'my-profile.settings.passwords-match': 'Passwords must match',

  'app.page.content.expand': 'Expand',
};
