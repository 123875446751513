// There will be Polish translation strings
export const pl = {
  'routes.home': '/',
  'routes.login': '/login',
  'routes.sign-up': '/signup',
  'routes.my-profile': '/my-profile',
  'routes.profile-settings': '/my-profile/settings',

  // App
  'app.get-app.get-ready': 'Przygotujcie się na wyjazd z naszą darmową aplikacją',
  'app.get-app.personalized-list': 'Spersonalizowana lista rzeczy do zabrania',
  'app.get-app.todo-list': 'Lista rzeczy do zrobienia przed wyjazdem',
  'app.get-app.trusted-destination': 'Zaufane informacje o miejscu docelowym',
  'app.get-app.plan-trip': 'Zaplanuj wyjazd',
  'app.get-app.or': 'lub',

  // Toast messages
  'app.toast.server-error': 'Nie udało się pobrać danych z serwera. Prosimy spróbować później.',
  'app.toast.profile.updated': 'Profil został zaktualizowany',
  'app.toast.profile.update.failed': 'Nie udało się zaktualizować profilu',
  'app.toast.profile.delete.failed': 'Nie udało się usunąć konta',

  // My profile
  'my-profile.greeting': 'Cześć',
  'my-profile.settings': 'Ustawienia',
  'my-profile.navigation.pack-for-trip': 'Spakuj się na wyjazd',
  'my-profile.navigation.your-travels': 'Twoje podróże',
  'my-profile.navigation.my-traveler-type.test-finished': 'Mój typ podróżnika',
  'my-profile.navigation.my-traveler-type.test-unfinished': 'Odkryj swój profil podróżnika',
  'my-profile.navigation.compare-with-friends': 'Porównaj profil ze znajomymi',
  'my-profile.navigation.my-travel-guides': 'Moje przewodniki',

  // Profile settings
  'my-profile.settings.back': 'Wstecz',
  'my-profile.settings.name': 'Imię',
  'my-profile.settings.email': 'Adres email',
  'my-profile.settings.newsletter': 'Newsletter MyLuggage',
  'my-profile.settings.newsletter-text': 'Chcę otrzymywać informacje o promocjach i zniżkach MyLuggage.',
  'my-profile.settings.change-password': 'Zmiana hasła',
  'my-profile.settings.current-password': 'Obecne hasło',
  'my-profile.settings.new-password': 'Nowe hasło',
  'my-profile.settings.repeat-new-password': 'Powtórz nowe hasło',
  'my-profile.settings.save-changes': 'Zapisz zmiany',
  'my-profile.settings.delete-account': 'Usuń konto',
  'my-profile.settings.delete-account.confirmation': 'Czy jesteś pewny, że chcesz usunąć konto',
  'my-profile.settings.delete-account.yes': 'Ta, usuń',
  'my-profile.settings.delete-account.cancel': 'Nie, anuluj',

  'my-profile.settings.password.required': 'Current password is required',
  'my-profile.settings.new_password.required': 'New password is required',
  'my-profile.settings.new_password_confirmation.required': 'Repeat your new password',
  'my-profile.settings.passwords-match': 'Passwords must match',

  'app.page.content.expand': 'Rozwiń',
};
