import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { ComparePreferencesOverview } from '../ComparePreferencesOverview';

import './ComparePreferences.scss';
import ArrowNext from 'assets/arrow-next.svg';

const createUser = (
  name,
  color,
  learning_percentage,
  leisure_percentage,
  accuracy_percentage,
  spontaneity_percentage
) => ({
  name,
  color,
  traveler_profile: {
    learning_percentage,
    leisure_percentage,
    accuracy_percentage,
    spontaneity_percentage,
  },
});

const users = [
  createUser('Mateusz', '#f7a035', 76, 24, 24, 76),
  createUser('Anna', '#de5505', 48, 52, 47, 53),
  createUser('Jurek', '#b1deb5', 60, 40, 80, 20),
  createUser('Karolina', '#65aca6', 74, 26, 69, 31),
];

export const ComparePreferences = ({ renderHeader }) => {
  const { localize } = useTranslation();

  const renderComparisonStep = (number, label, showArrow = true) => {
    return (
      <div className="comparison-step">
        <h3 className="comparison-step__number">{number}</h3>
        <h3 className="comparison-step__label" style={{ marginRight: number === '03' ? '0' : '30px' }}>
          {label}
        </h3>
        {showArrow && <img src={ArrowNext} alt="arrow" className="comparison-step__arrow" />}
      </div>
    );
  };

  return (
    <div className="compare-preferences" id="compare-preferences">
      {renderHeader && renderHeader()}
      <div className="compare-preferences__comparison-steps">
        {renderComparisonStep('01', localize('traveler-test.result.send-survey-to-people'))}
        {renderComparisonStep('02', localize('traveler-test.result.compare-your-results'))}
        {renderComparisonStep('03', localize('traveler-test.result.discover-differences-between-you'), false)}
      </div>
      <div className="compare-preferences__comparison-steps-mobile">
        {renderComparisonStep('01', localize('traveler-test.result.send-survey-to-people'), false)}
        {renderComparisonStep('02', localize('traveler-test.result.compare-your-results'), false)}
        {renderComparisonStep('03', localize('traveler-test.result.discover-differences-between-you'), false)}
      </div>
      <div className="compare-preferences__overviews">
        <ComparePreferencesOverview
          title={localize('traveler-test.result.how-you-spend-time')}
          leftLabel={localize('traveler-test.result.leisure')}
          rightLabel={localize('traveler-test.result.learning')}
          leftPercentKey="learning_percentage"
          rightPercentKey="leisure_percentage"
          users={users}
          border="solid 1px #DFDFDF"
        />
        <ComparePreferencesOverview
          title={localize('traveler-test.result.how-you-plan-trip')}
          leftLabel={localize('traveler-test.result.accuracy')}
          rightLabel={localize('traveler-test.result.spontaneity')}
          leftPercentKey="accuracy_percentage"
          rightPercentKey="spontaneity_percentage"
          users={users}
          border="solid 1px #DFDFDF"
        />
      </div>
    </div>
  );
};
