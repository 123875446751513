import React, { useEffect, useMemo, useContext } from 'react';
import * as uuid from 'uuid';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { PageLoading } from 'modules/page-state';
import { Space } from 'antd';
import { TravelerTypeLayout } from 'modules/layout/components/TravelerTypeLayout';
import { TravelerTypeQuestion } from './components/TravelerTypeQuestion';

import * as action from 'redux/traveler-test/actions';
import * as selector from './selectors';
import { sliceQuestions, createAnswer } from './helper';
import { GlobalContext } from 'AppContext';

import './TravelerTypePage.scss';

export const TravelerTypePage = () => {
  const { auth } = useContext(GlobalContext);
  const { locale } = useIntl();

  const dispatch = useDispatch();
  const page = useSelector(selector.getPage);
  const questions = useSelector(selector.getQuestions);
  const loading = useSelector(selector.getLoading);

  const pageQuestions = useMemo(() => sliceQuestions(questions, page), [questions, page]);

  useEffect(() => {
    const guid = auth.getToken();
    const lang = locale;

    dispatch(action.startTestRequest({ guid, lang }));
  }, [dispatch, auth, locale]);

  useEffect(() => {
    if (!pageQuestions) return;
    const answers = pageQuestions?.map(({ id }) => createAnswer(id));

    dispatch(action.setAnswers(answers));
  }, [pageQuestions, dispatch]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <TravelerTypeLayout>
      <div className="traveler-type-main">
        <Space align="center" direction="vertical" size={80}>
          {pageQuestions?.map(({ id, question }) => (
            <TravelerTypeQuestion key={uuid.v4()} id={id} title={question} />
          ))}
        </Space>
      </div>
    </TravelerTypeLayout>
  );
};
