/**
 * @param {string} title
 * @param {string} href
 * @param {array} sublinks
 */
export const createLink = (title, href, sublinks) => ({
  title,
  href,
  sublinks,
});
