import Cookies from 'js-cookie';

export const useCookie = () => {
  const setCookie = (name, value, expires) => {
    Cookies.set(name, value, { expires });
  };

  const getCookie = (name) => {
    return Cookies.get(name);
  };

  return { setCookie, getCookie };
};
