import { useTranslation } from 'utils/hooks/app/useTranslation';

const createStrength = (label, text, iconKey) => ({ label, text, iconKey });

export const useData = () => {
  const { localize } = useTranslation();

  const topStrengths = [
    createStrength(
      localize('traveler-test.landing.surprise-fan'),
      localize('traveler-test.landing.surprise-fan.text'),
      'surprises'
    ),
    createStrength(
      localize('traveler-test.landing.bargain-hunter'),
      localize('traveler-test.landing.bargain-hunter.text'),
      'bargain_hunter'
    ),
    createStrength(
      localize('traveler-test.landing.culture-fan'),
      localize('traveler-test.landing.culture-fan.text'),
      'culture_fan'
    ),
  ];

  const bottomStrengths = [
    createStrength(
      localize('traveler-test.landing.organizer'),
      localize('traveler-test.landing.organizer.text'),
      'organiser'
    ),
    createStrength(
      localize('traveler-test.landing.born-planner'),
      localize('traveler-test.landing.born-planner.text'),
      'planner'
    ),
    createStrength(
      localize('traveler-test.landing.photographer'),
      localize('traveler-test.landing.photographer.text'),
      'photographer'
    ),
  ];

  return { topStrengths, bottomStrengths };
};
