import { formalizeReturn } from './utils';

export const getUserGeolocation = async () => {
  const response = await fetch('http://ip-api.com/json');
  return await response.json();
};

export const getMe = async ({ lang, guid }) => {
  const response = await fetch(`/${lang}/profile/me`, {
    headers: {
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const updateMe = async ({
  lang,
  guid,
  name,
  marketing_consent,
  password,
  new_password,
  new_password_confirmation,
}) => {
  const response = await fetch(`/${lang}/profile/me`, {
    method: 'POST',
    body: JSON.stringify({ name, marketing_consent, password, new_password, new_password_confirmation }),
    headers: { 'app-language': lang, guid, 'Content-Type': 'application/json' },
  });

  return await formalizeReturn(response);
};

export const deleteMe = async ({ lang, guid }) => {
  const response = await fetch(`/${lang}/profile/me/delete`, {
    method: 'POST',
    headers: { 'app-language': lang, guid },
  });

  return await formalizeReturn(response);
};
