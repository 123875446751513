import { useEffect } from 'react';
import { useCookie } from 'utils/hooks/app/storage/useCookie';
import * as uuid from 'uuid';

export const useAuth = () => {
  const NAME = 'guid';
  const { setCookie, getCookie } = useCookie();

  const checkToken = () => {
    return !!getToken();
  };

  const setToken = (token) => {
    setCookie(NAME, token);
  };

  const getToken = () => {
    return getCookie(NAME);
  };

  useEffect(() => {
    if (!checkToken()) setToken(uuid.v4());
  });

  return { checkToken, setToken, getToken };
};
