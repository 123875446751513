import React from 'react';
import { useTranslation } from 'utils/hooks';

import { Button } from 'antd';

import { GooglePlayBadge } from 'assets/components/GooglePlayBadge';
import { AppStoreBadge } from 'assets/components/AppStoreBadge';

import './DownloadApp.scss';

const APP_STORE_URL = 'https://apps.apple.com/app/apple-store/id1487080209?pt=118795907&ct=badge&mt=8';
const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=io.myluggage.app&utm_source=myluggage.io&utm_medium=badge&utm_campaign=comparison';

export const DownloadApp = ({ buttonText, onButtonClick, buttonDisabled = false, onlyBadges = false }) => {
  const { localize } = useTranslation();

  const onDownload = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="download-app">
      {!onlyBadges && (
        <>
          <div type="primary" className="download-app__plan-trip">
            <Button disabled={buttonDisabled} onClick={onButtonClick} type="primary">
              {buttonText}
            </Button>
          </div>
          <h4 className="download-app__or">{localize('app.get-app.or')}</h4>
        </>
      )}
      <div className="download-app__badges">
        <GooglePlayBadge onClick={() => onDownload(GOOGLE_PLAY_URL, 'google_play')} className="download-app__badge" />
        <AppStoreBadge onClick={() => onDownload(APP_STORE_URL, 'app_store')} className="download-app__badge" />
      </div>
    </div>
  );
};
