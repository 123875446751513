import React from 'react';
import { FriendsList } from '../FriendsList';
import { SelectedFriendsList } from '../SelectedFriendsList';

import './ResultsComparison.scss';

export const ResultsComparison = ({ user }) => {
  return (
    <div className="results-comparison">
      <FriendsList />
      <SelectedFriendsList user={user} />
    </div>
  );
};
