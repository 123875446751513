import { put, call } from 'redux-saga/effects';
import { AppMessage } from 'const/app-messages';

import * as api from 'api/test-comparison';
import * as actions from './actions';
import * as appAction from 'redux/app/actions';

export function* getFriendsWorker(action) {
  try {
    yield put(actions.setLoading(true));

    const response = yield call(api.getFriends, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.getFriendsResponse(response.data));
        yield put(actions.setLoading(false));
        break;

      case 401:
        yield put(actions.setAuthorized(false));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* sendComparisonDataWorker(action) {
  try {
    yield put(actions.setLoading(true));

    const response = yield call(api.sendComparisonData, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.sendComparisonDataResponse(response.data));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}

export function* getComparisonWorker(action) {
  try {
    yield put(actions.setLoading(true));

    const response = yield call(api.getComparison, action.payload);

    switch (response.status) {
      case 200:
        yield put(actions.getComparisonResponse(response.data));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}
