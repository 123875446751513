import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { usersData } from './data';

import { TasksOverview } from 'pages/apps/personality-test/components/TasksOverview';
import { StartTestButton } from '../StartTestButton';

import './ShareTasks.scss';

export const ShareTasks = () => {
  const { localize } = useTranslation();

  return (
    <div className="share-tasks">
      <div className="share-tasks__header">
        <h2 className="share-tasks__title">{localize('traveler-test.landing.share-tasks')}</h2>
        <h3 className="share-tasks__subtitle">{localize('traveler-test.landing.see-who-should-take-care')}</h3>
      </div>
      <TasksOverview profiles={usersData} unit="tasks" unitLocalizationPrefix="traveler-test.landing" />
      <div className="share-tasks__start-test">
        <StartTestButton id="take-test-button" text={localize('traveler-test.landing.take-test')} />
      </div>
    </div>
  );
};
