import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { useMultilangRoute } from 'utils/hooks/app/useMultilangRoute';

import { AppRoute } from 'const';

import './BackButton.scss';
import Arrow from 'assets/arrow-next.svg';

export const BackButton = () => {
  const { localize } = useTranslation();
  const { redirect } = useMultilangRoute();

  const onBackButtonClick = () => {
    redirect(AppRoute.MyProfile);
  };

  return (
    <div onClick={onBackButtonClick} className="profile-settings__back-button">
      <img src={Arrow} alt="arrow" className="profile-settings__back-button-icon" />
      <span className="profile-settings__back-button-text">{localize('my-profile.settings.back')}</span>
    </div>
  );
};
