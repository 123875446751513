import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { icons } from './icons';

import { StrengthsList } from './components/StrengthsList';
import { StartTestButton } from '../StartTestButton';

import { useData } from './useData';

import './LandingStrengths.scss';

export const LandingStrengths = () => {
  const { localize } = useTranslation();
  const { topStrengths, bottomStrengths } = useData();

  return (
    <div className="strengths">
      <div className="strengths__header">
        <h2 className="strengths__title">{localize('traveler-test.landing.know-strengths')}</h2>
        <h3 className="strengths__subtitle">{localize('traveler-test.landing.how-get-most-of-trip')}</h3>
      </div>
      <StrengthsList
        strengths={topStrengths}
        title={localize('traveler-test.landing.specializations')}
        subtitle={localize('traveler-test.landing.activities-that-pleasant')}
        icons={icons}
      />
      <StrengthsList
        strengths={bottomStrengths}
        title={localize('traveler-test.result.traveler-skills')}
        subtitle={localize('traveler-test.landing.talents-that-built-travel')}
        icons={icons}
      />
      <div className="strengths__start-test">
        <StartTestButton id="go-to-test-button" text={localize('traveler-test.landing.go-to-test')} />
      </div>
    </div>
  );
};
