import * as type from './types';

const initialState = {
  user: null,
  friends: [],
  selectedFriends: [],
  loading: null,

  comparisonDataResponse: null,
  comparisonResponse: null,
  authorized: null,
};

export const testComparison = (state = initialState, action) => {
  switch (action.type) {
    case type.SELECT_FRIEND: {
      const friend = { ...action.payload, selected: true };
      return {
        ...state,
        selectedFriends: [...new Set([...state.selectedFriends, friend])],
      };
    }

    case type.DESELECT_FRIEND: {
      return {
        ...state,
        selectedFriends: state.selectedFriends.filter(({ id }) => id !== action.payload.id),
      };
    }

    case type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case type.SET_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload,
      };

    case type.GET_FRIENDS_REQUEST:
      return state;

    case type.SEND_COMPARISON_DATA_REQUEST:
      return state;

    case type.GET_COMPARISON_REQUEST:
      return state;

    case type.GET_FRIENDS_RESPONSE: {
      const user = action.payload;
      const friends = user.friends;

      return {
        ...state,
        friends,
        user,
        selectedFriends: [],
      };
    }

    case type.SEND_COMPARISON_DATA_RESPONSE: {
      return {
        ...state,
        comparisonDataResponse: action.payload,
      };
    }

    case type.GET_COMPARISON_RESPONSE:
      return {
        ...state,
        comparisonResponse: action.payload,
      };

    case type.CLEAN_COMPARISON_DATA_RESPONSE:
      return {
        ...state,
        comparisonDataResponse: null,
      };

    default:
      return state;
  }
};
