import React, { useMemo, useEffect, useContext } from 'react';
import * as uuid from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { useMultilangRoute } from 'utils/hooks/app/useMultilangRoute';

import { Progress, Button } from 'antd';
import { animateScroll as scroll, Link } from 'react-scroll';

import * as action from 'redux/traveler-test/actions';
import * as selector from './selectors';
import { getCenterOffset } from 'pages/apps/personality-test/TravelerTypePage/components/TravelerTypeQuestion/helper';
import { GlobalContext } from 'AppContext';
import { AppRoute } from 'const';

import './TravelerTypeLayout.scss';
import EmailIcon from 'assets/traveler-test/email.svg';
import TimeIcon from 'assets/traveler-test/time.svg';

export const TravelerTypeLayout = ({ children }) => {
  const { personalityTest, auth } = useContext(GlobalContext);
  const { locale } = useIntl();
  const { redirect } = useMultilangRoute();
  const { localize } = useTranslation();
  const dispatch = useDispatch();
  const { progress, answers, questions, page, loading, finishTestResponse } = useSelector(selector.getState);

  const nextDisabled = useMemo(() => answers?.some(({ answer }) => answer === 0), [answers]);

  useEffect(() => {
    if (!finishTestResponse) return;

    if (finishTestResponse.status === 200) {
      const testGuid = personalityTest.getTestGuid();
      personalityTest.setFinishedTestGuid(testGuid ?? uuid.v4());

      const finishedTestGuid = personalityTest.getFinishedTestGuid();
      redirect(AppRoute.TravelerTestResult, [finishedTestGuid]);
    }
  }, [finishTestResponse, personalityTest, redirect]);

  useEffect(() => {
    const pageAnswered = answers.filter(({ answer }) => answer > 0);
    const totalNumberOfAnswered = (page - 1) * 12 + pageAnswered.length;
    const percent = (totalNumberOfAnswered / questions.length) * 100;

    dispatch(action.setProgress(percent));
  }, [answers, page, questions, dispatch]);

  const sendAnswers = () => {
    const lang = locale;
    const guid = auth.getToken();
    const testGuid = personalityTest.getTestGuid();

    dispatch(action.sendAnswersRequest({ page, answers, testGuid, guid, lang }));
    scroll.scrollToTop();
  };

  const finishTest = () => {
    const lang = locale;
    const guid = auth.getToken();
    const testGuid = personalityTest.getTestGuid();

    dispatch(action.finishTestRequest({ answers, testGuid, guid, lang }));
  };

  const onNextButtonClick = () => {
    if (nextDisabled) return;
    page !== questions.length / 12 ? sendAnswers() : finishTest();
  };

  const nextButtonRender = () => {
    const topUnselectedAnswer = answers?.find(({ answer }) => answer === 0);

    const classList = `traveler-type-progress__next 
      ${nextDisabled ? 'traveler-type-progress__next--disabled' : ''}`;

    const render = () => (
      <Button
        id="next-button"
        onClick={onNextButtonClick}
        loading={loading}
        className={classList}
        style={{
          borderRadius: 7,
          padding: '0 55px',
          height: 43,
          fontSize: 16,
          fontWeight: 900,
        }}
        type="primary">
        {localize('traveler-test.next-button')}
      </Button>
    );

    return topUnselectedAnswer ? (
      <Link
        to={`question-${topUnselectedAnswer.id}`}
        smooth
        duration={500}
        offset={-getCenterOffset(window.innerHeight, 300)}>
        {render()}
      </Link>
    ) : (
      render()
    );
  };

  return (
    <div className="traveler-type-test">
      <div className="traveler-type-test__content">
        <div className="traveler-type-header">
          <div className="traveler-type-container">
            <h1 className="traveler-type-header__title">{localize('traveler-test.title')}</h1>
            <div className="traveler-type-header__description">
              <div className="traveler-type-header__benefit">
                <img src={TimeIcon} className="traveler-type-header__icon" alt="time" />
                <span className="traveler-type-header__label">{localize('traveler-test.takes-time')}</span>
              </div>
              <div className="traveler-type-header__benefit">
                <img src={EmailIcon} className="traveler-type-header__icon" alt="email" />
                <span className="traveler-type-header__label">{localize('traveler-test.without-registration')}</span>
              </div>
            </div>
          </div>
        </div>

        {children}

        <div className="traveler-type-progress">
          <div className="traveler-type-progress__wrapper">
            <div className="traveler-type-progress__progressbar">
              <Progress trailColor="#d8d8d8" percent={progress} strokeColor="#f9785c" showInfo={false} />
            </div>
            {nextButtonRender()}
          </div>
        </div>

        <div className="traveler-type-progress__progressbar--mobile">
          <Progress trailColor="#d8d8d8" percent={progress} strokeColor="#f9785c" showInfo={false} />
        </div>
      </div>
    </div>
  );
};
