import React from 'react';
import { useTranslation } from 'utils/hooks';

import { TasksOverview } from 'pages/apps/personality-test/components/TasksOverview';

import './TasksComparison.scss';

export const TasksComparison = ({ profiles }) => {
  const { localize } = useTranslation();

  return (
    <div className="tasks-comparison">
      <div className="tasks-comparison__item">
        <h3 className="tasks-comparison__title">
          {localize('traveler-test.comparison.results.tasks.how-you-handle-tasks')}
        </h3>
        <TasksOverview
          profiles={profiles}
          unit="tasks"
          unitLocalizationPrefix="traveler-test.comparison.results.tasks"
        />
      </div>
      <div className="tasks-comparison__item">
        <h3 className="tasks-comparison__title">
          {localize('traveler-test.comparison.results.tasks.preferred-location')}
        </h3>
        <TasksOverview
          profiles={profiles}
          unit="location"
          unitLocalizationPrefix="traveler-test.comparison.results.location"
        />
      </div>
    </div>
  );
};
