import React from 'react';
import { useTranslation } from 'utils/hooks';

import { StartTripForm } from 'forms/StartTripForm';

import './StartTripPage.scss';

import PhoneIcon from 'assets/start-trip/phone.png';
import BackGroundTree from 'assets/start-trip/background-tree.png';
import BrandHeroIcon from 'assets/start-trip/brand-hero.png';
import BrandHeroMobileIcon from 'assets/start-trip/brand-hero.mobile.png';
import SpotIcon from 'assets/start-trip/spot.png';

export const StartTripPage = () => {
  const { localize } = useTranslation();

  return (
    <div className="start-trip">
      <div className="start-trip__background background">
        <div className="background__spots">
          <img src={SpotIcon} alt="spot" data-behind className="background__spot" />
          <img src={SpotIcon} alt="spot" className="background__spot" />
        </div>
        <div className="background__bottom-area">
          <img src={BackGroundTree} alt="tree" className="background__trees" />
          <img src={BrandHeroIcon} alt="hero" className="background__hero" />
        </div>
      </div>
      <div className="start-trip__grid">
        <div className="start-trip__img-container">
          <img src={PhoneIcon} alt="phone" className="start-trip__img" />
        </div>
        <div className="start-trip__packing">
          <div className="start-trip__mobile-top-bar">
            <img src={BrandHeroMobileIcon} alt="hero" className="start-trip__mobile-hero" />
            <span className="start-trip__hashtag">{localize('packing.start-trip.hashtag')}</span>
          </div>
          <h2 className="start-trip__title">{localize('packing.start-trip.personalised-list')}</h2>
          <StartTripForm />
        </div>
      </div>
    </div>
  );
};
