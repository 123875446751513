import React from 'react';

import './StrengthsItem.scss';
import StrengthIcon from 'assets/traveler-test/castle.svg';

export const StrengthsItem = ({ label, text, icon }) => {
  return (
    <div className="strength">
      <div className="strength__header">
        <img src={icon ?? StrengthIcon} alt="castle" className="strength__icon" />
        <h3 className="strength__label">{label}</h3>
      </div>
      <p className="strength__text">{text}</p>
    </div>
  );
};
