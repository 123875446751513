import { createLink } from 'modules/anchor';

export const getAnchorConfig = (localize) => [
  createLink(localize('traveler-test.result.traveler-profile'), 'traveler-profile'),
  createLink(localize('traveler-test.result.traveler-preference'), 'traveler-preference', [
    createLink(localize('traveler-test.result.interesting-facts'), 'interesting-facts'),
    createLink(localize('traveler-test.result.traveler-specializations'), 'traveler-specs'),
    createLink(localize('traveler-test.result.traveler-skills'), 'traveler-skills'),
  ]),
  createLink(localize('traveler-test.result.travel-personality'), 'travel-personality', [
    createLink(localize('traveler-test.result.travel-planning'), 'travel-planning'),
  ]),
  createLink(localize('traveler-test.result.destinations.recommended-destination'), 'destinations'),
  createLink(localize('traveler-test.result.compare-preferences'), 'compare-preferences'),
];
