import { useEffect } from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { useSelector } from 'react-redux';

import { message as toast } from 'antd';
import * as selector from './selectors';

export const Toast = () => {
  const { localize } = useTranslation();
  const appToast = useSelector(selector.getToast);

  useEffect(() => {
    appToast && toast[appToast.type](localize(appToast.code));
  }, [appToast, localize]);

  return null;
};
