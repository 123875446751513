import React, { useContext, useEffect } from 'react';
import { useMultilangRoute } from 'utils/hooks';
import { useSelector, useDispatch } from 'react-redux';

import { GlobalContext } from 'AppContext';
import * as selector from './selectors.js';
import * as action from 'redux/user/actions';

import { PageLoading } from 'modules/page-state/components/PageLoading/PageLoading';
import { BackButton } from './components/BackButton';
import { ProfileSettingsForm } from 'forms/ProfileSettingsForm';

import './ProfileSettings.scss';

export const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { forceRedirect, AppRoute } = useMultilangRoute();
  const { me } = useContext(GlobalContext);
  const deleteResult = useSelector(selector.getDeleteResult);
  const updateResult = useSelector(selector.getUpdateResult);

  useEffect(() => {
    if (updateResult?.status === 200) {
      me.refreshMe();
      dispatch(action.updateMeResponse(null));
    }
  }, [updateResult, me, dispatch]);

  useEffect(() => {
    if (deleteResult?.status === 200) {
      forceRedirect(AppRoute.Home);
    }
  }, [deleteResult, forceRedirect, AppRoute]);

  if (me.loading) {
    return <PageLoading />;
  }

  return (
    <div className="profile-settings">
      <div className="profile-settings__container">
        <BackButton />
        <ProfileSettingsForm user={me?.me?.user} />
      </div>
    </div>
  );
};
