import React from 'react';
import { useMultilangRoute } from 'utils/hooks/app/useMultilangRoute';

import { Button } from 'antd';
import { AppRoute } from 'const/app-routes';

import './StartTestButton.scss';

export const StartTestButton = ({ id, text }) => {
  const { redirect } = useMultilangRoute();

  const onStartTestClick = () => {
    redirect(AppRoute.TravelerTest);
  };

  return (
    <div className="start-test-button">
      <Button id={id} onClick={onStartTestClick} type="primary">
        {text}
      </Button>
    </div>
  );
};
