import React, { useMemo, useState, useContext } from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { profileSettingsSchema, fields } from 'utils/form-schemas/profile-settings.schema';
import * as action from 'redux/user/actions';
import { GlobalContext } from 'AppContext';

import { Button, Modal } from 'antd';
import { TextInput } from '../components/TextInput';
import { Checkbox } from '../components/Checkbox';

import './ProfileSettingsForm.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const ProfileSettingsForm = ({ user }) => {
  const { auth, me } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const { localize, locale } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const lang = locale;
  const guid = auth.getToken();
  const { values, errors, handleChange, handleSubmit, setFieldValue, setFieldError } = useFormik(
    profileSettingsSchema({ name: user?.name, email: user?.email })
  );

  const disabled = useMemo(() => !!Object.keys(errors).length, [errors]);

  const onDeleteAccountClick = () => {
    Modal.confirm({
      title: localize('my-profile.settings.delete-account.confirmation'),
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      okText: localize('my-profile.settings.delete-account.yes'),
      cancelText: localize('my-profile.settings.delete-account.cancel'),
      okButtonProps: { danger: true },
      onCancel: () => setModalVisible(false),
      onOk: deleteAccount,
    });
  };

  const deleteAccount = () => {
    setModalVisible(false);
    dispatch(action.deleteMeRequest({ guid, lang }));
  };

  const onSubmit = () => {
    const data = { ...values, marketing_consent: +values.marketing_consent };
    delete data.email;

    dispatch(action.updateMeRequest({ lang, guid, ...data }));
  };

  const passwordValidate = () => {
    const { password, new_password, new_password_confirmation } = values;

    if (!new_password && password) {
      setFieldError(fields.new_password, localize(`my-profile.settings.new_password.required`));
    }

    if (!new_password_confirmation && password) {
      setFieldError(
        fields.new_password_confirmation,
        localize(`my-profile.settings.new_password_confirmation.required`)
      );
    }

    if (new_password !== new_password_confirmation && password) {
      setFieldError(fields.new_password_confirmation, localize(`my-profile.settings.passwords-match`));
    }

    if ((new_password || new_password_confirmation) && !password) {
      setFieldError(fields.password, localize(`my-profile.settings.password.required`));
    }
  };

  const onPasswordBlur = () => {
    passwordValidate();
  };

  return (
    <>
      <Modal visible={modalVisible} />
      <div className="profile-settings-form">
        <div className="profile-settings-form__header">
          <h2 className="profile-settings-form__title">{localize('my-profile.settings')}</h2>
        </div>
        <form className="profile-settings-form__form-data form-data" onSubmit={handleSubmit}>
          <div className="form-data__fields">
            <div className="form-data__personal-data">
              <TextInput
                name={fields.name}
                placeholder={localize('my-profile.settings.name')}
                label={localize('my-profile.settings.name')}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
              />
              <TextInput
                name={fields.email}
                disabled
                id="email"
                placeholder={localize('my-profile.settings.email')}
                label={localize('my-profile.settings.email')}
                value={values.email}
                onChange={handleChange}
              />
              <Checkbox
                name={fields.marketing_consent}
                title={localize('my-profile.settings.newsletter')}
                label={localize('my-profile.settings.newsletter-text')}
                value={values.marketing_consent}
                onChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="form-data__security">
              <TextInput
                name={fields.password}
                type="password"
                placeholder={localize('my-profile.settings.current-password')}
                label={localize('my-profile.settings.change-password')}
                value={values.password}
                onChange={handleChange}
                onBlur={onPasswordBlur}
                error={errors.password}
              />
              <TextInput
                name={fields.new_password}
                type="password"
                placeholder={localize('my-profile.settings.new-password')}
                value={values.new_password}
                onChange={handleChange}
                onBlur={onPasswordBlur}
                error={errors.new_password}
              />
              <TextInput
                name={fields.new_password_confirmation}
                type="password"
                placeholder={localize('my-profile.settings.repeat-new-password')}
                value={values.new_password_confirmation}
                onChange={handleChange}
                onBlur={onPasswordBlur}
                error={errors.new_password_confirmation}
              />
            </div>
          </div>
          <div className="form-data__actions">
            <div className="form-data__save-changes">
              <Button loading={me?.loading} onClick={onSubmit} id={uuid.v4()} disabled={disabled} type="primary">
                {localize('my-profile.settings.save-changes')}
              </Button>
            </div>
            <div onClick={onDeleteAccountClick} className="form-data__delete-account">
              <Button id={uuid.v4()}>{localize('my-profile.settings.delete-account')}</Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
