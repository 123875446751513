import React from 'react';
import { Toast } from 'modules/page-state';

export const AppLayout = ({ children }) => {
  return (
    <main>
      <Toast />
      {children}
    </main>
  );
};
