import { formalizeReturn } from './utils';

export const startTest = async ({ guid, lang }) => {
  const response = await fetch('/api/personality-test/start', {
    method: 'POST',
    headers: {
      guid,
      'app-language': lang,
    },
  });

  return await formalizeReturn(response);
};

export const sendAnswers = async ({ page, answers, testGuid, guid, lang }) => {
  const response = await fetch(`/api/personality-test/answer/${page}`, {
    method: 'POST',
    body: JSON.stringify({ answers, personality_test_uuid: testGuid }),
    headers: {
      'Content-type': 'application/json',
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const finishTest = async ({ answers, testGuid, guid, lang }) => {
  const response = await fetch('/api/personality-test/answer/finish', {
    method: 'POST',
    body: JSON.stringify({ answers, personality_test_uuid: testGuid }),
    headers: {
      'Content-type': 'application/json',
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const getResult = async ({ lang, guid, testGuid }) => {
  const response = await fetch(`/api/personality-test/results/${testGuid}`, {
    headers: {
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const getDestinations = async ({ lang, guid }) => {
  const response = await fetch('/api/personality-test/destinations', {
    headers: {
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const rateDestination = async ({ guid, id, rate }) => {
  const response = await fetch(`/api/personality-test/destinations/${id}/rate`, {
    method: 'POST',
    headers: {
      guid,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ rate }),
  });

  return await formalizeReturn(response);
};
