import React from 'react';

import { Radio } from 'antd';

import './RadioGroup.scss';

export const RadioGroup = ({ name, value, onChange }) => {
  return (
    <div className="start-trip__radio-group">
      <Radio.Group name={name} value={value} onChange={onChange}>
        <Radio value={1}>Man</Radio>
        <Radio value={2}>Woman</Radio>
      </Radio.Group>
    </div>
  );
};
