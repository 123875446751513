import React from 'react';
import * as moment from 'moment';
import { DatePicker } from 'antd';

import './DateRangePicker.scss';
import CalendarIcon from 'assets/start-trip/calendar.png';

const today = moment();

export const DateRangePicker = ({
  startValue,
  endValue,
  startName,
  endName,
  startPlaceholder,
  endPlaceholder,
  setFieldValue,
}) => {
  const disabledStartDate = (date) => {
    if (date.isBefore(today)) return true;
    if (!endValue) return false;

    return date.isBefore(moment(endValue).add(-1, 'month')) || date.isAfter(moment(endValue));
  };

  const disabledEndDate = (date) => {
    if (!startValue) return false;

    return date.isAfter(moment(startValue).add(1, 'month')) || date.isBefore(startValue) || date.isBefore(today);
  };

  const onDateChange = (name, date) => {
    setFieldValue(name, date?.toDate());
  };

  const renderSuffix = () => {
    return <img src={CalendarIcon} alt="calendar" />;
  };

  return (
    <div className="daterange-picker">
      <DatePicker
        value={startValue ? moment(startValue) : undefined}
        disabledDate={disabledStartDate}
        name={startName}
        placeholder={startPlaceholder}
        onChange={(date) => onDateChange(startName, date)}
        showToday={false}
        suffixIcon={renderSuffix()}
      />
      <DatePicker
        value={endValue ? moment(endValue) : undefined}
        disabledDate={disabledEndDate}
        name={endName}
        placeholder={endPlaceholder}
        onChange={(date) => onDateChange(endName, date)}
        showToday={false}
        suffixIcon={renderSuffix()}
      />
    </div>
  );
};
