import { Swiper, Pagination } from 'swiper/js/swiper.esm';

export const getSwiperParams = () => ({
  Swiper,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    bulletElement: 'div',
  },
  width: window.innerWidth - 20,
  centeredSlidesBounds: true,
  spaceBetween: 10,
  containerModifierClass: 'swiper-section__container',
});
