import React, { useContext } from 'react';
import { useMultilangRoute, useTranslation } from 'utils/hooks';

import { GlobalContext } from 'AppContext';

import { Button } from 'pages/apps/general/components/Button';
import { StartTestButton } from '../StartTestButton';
import { TravelPreferences } from '../TravelPreferences';

import TravelerImage from 'assets/traveler-test/landing.desktop.png';
import TravelerMobileImage from 'assets/traveler-test/landing.mobile.png';
import EmailIcon from 'assets/traveler-test/email.svg';
import TimeIcon from 'assets/traveler-test/time.svg';

import './LandingHeader.scss';

export const LandingHeader = () => {
  const { personalityResults, personalityTest } = useContext(GlobalContext);

  const { redirect, AppRoute } = useMultilangRoute();
  const { localize } = useTranslation();
  const testGuid = personalityResults?.personality_test?.uuid ?? personalityTest.getFinishedTestGuid();

  const redirectToTestResults = () => {
    redirect(AppRoute.TravelerTestResult, [testGuid]);
  };

  const redirectToTest = () => {
    redirect(AppRoute.TravelerTest);
  };

  const renderButtons = () => {
    const testFinished = !!testGuid;

    if (testFinished) {
      return (
        <>
          <Button
            id="see-my-results-button"
            clickHandler={redirectToTestResults}
            type="primary"
            text={localize('traveler-test.comparison.results.see-results')}
          />
          <h3 className="take-test__or">{localize('app.get-app.or')}</h3>
          <Button
            id="retake-test-button"
            clickHandler={redirectToTest}
            type="default"
            text={localize('traveler-test.comparison.results.retake-test')}
          />
        </>
      );
    }

    return <StartTestButton text={localize('traveler-test.landing.take-free-test')} />;
  };

  return (
    <div className="landing-header">
      <div className="understand-preferences">
        <div className="understand-preferences__overview">
          <TravelPreferences />
        </div>
        <div className="understand-preferences__slogan slogan">
          <img src={TravelerImage} alt="traveler" className="slogan__img" />
          <div className="slogan__mobile-img-wrapper">
            <img src={TravelerMobileImage} alt="traveler" className="slogan__mobile-img" />
          </div>
          <div className="slogan__text">
            <h3 className="slogan__title">{localize('traveler-test.landing.understand-preferences')}</h3>
            <span className="slogan__subtitle">{localize('traveler-test.landing.find-out-what-enjoy')}</span>
          </div>
        </div>
      </div>
      <div className="take-test">
        <div className="take-test__advantages">
          <div className="take-test__advantage">
            <img src={EmailIcon} alt="mail" className="take-test__icon" />
            <span className="take-test__label">{localize('traveler-test.without-registration')}</span>
          </div>
          <div className="take-test__advantage">
            <img src={TimeIcon} alt="time" className="take-test__icon" />
            <span className="take-test__label">{localize('traveler-test.takes-time')}</span>
          </div>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};
