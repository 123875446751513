import React, { useRef } from 'react';

import './Checkbox.scss';
import CheckmarkIcon from 'assets/my-profile/checkmark.svg';

export const Checkbox = ({ id, name, value, title, label, onChange, setFieldValue }) => {
  const inputRef = useRef();

  const renderCheckmark = () => {
    const modifier = value ? 'checkbox__checkmark--checked' : '';
    const className = `checkbox__checkmark ${modifier}`;

    return <img src={CheckmarkIcon} alt="checkmark" className={className} />;
  };

  return (
    <div className="checkbox">
      <span className="checkbox__title">{title}</span>
      <div className="checkbox__field">
        <input
          ref={inputRef}
          type="checkbox"
          className="checkbox__hidden-field"
          id={id ?? name}
          name={name}
          checked={value}
          onChange={onChange}
        />
        <div className="checkbox__custom-box">{renderCheckmark()}</div>
        <label onClick={() => setFieldValue(name, !value)} htmlFor={id} className="checkbox__label">
          {label}
        </label>
      </div>
    </div>
  );
};
