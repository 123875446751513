import React from 'react';
import { useTranslation } from 'utils/hooks';

import { ComparePreferencesOverview } from 'pages/apps/personality-test/components/ComparePreferencesOverview';

import './PreferencesComparison.scss';

export const PreferencesComparison = ({ profiles }) => {
  const { localize } = useTranslation();

  return (
    <div className="preferences-comparison">
      <h2 className="preferences-comparison__title">{localize('traveler-test.comparison.results.your-preferences')}</h2>
      <div className="preferences-comparison__preferences">
        <ComparePreferencesOverview
          title={localize('traveler-test.comparison.results.spend-time')}
          leftLabel={localize('traveler-test.result.learning')}
          rightLabel={localize('traveler-test.result.leisure')}
          leftPercentKey="learning_percentage"
          rightPercentKey="leisure_percentage"
          users={profiles}
        />
        <ComparePreferencesOverview
          title={localize('traveler-test.comparison.results.what-environment')}
          leftLabel={localize('traveler-test.result.nature')}
          rightLabel={localize('traveler-test.result.city')}
          leftPercentKey="nature_percentage"
          rightPercentKey="city_percentage"
          users={profiles}
        />
        <ComparePreferencesOverview
          title={localize('traveler-test.comparison.results.your-risk-attitude')}
          leftLabel={localize('traveler-test.result.risk')}
          rightLabel={localize('traveler-test.result.safety')}
          leftPercentKey="risk_percentage"
          rightPercentKey="safety_percentage"
          users={profiles}
        />
        <ComparePreferencesOverview
          title={localize('traveler-test.comparison.results.plan-trips')}
          leftLabel={localize('traveler-test.result.accuracy')}
          rightLabel={localize('traveler-test.result.spontaneity')}
          leftPercentKey="accuracy_percentage"
          rightPercentKey="spontaneity_percentage"
          users={profiles}
        />
      </div>
    </div>
  );
};
