import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks';

import './Profiles.scss';

export const Profiles = ({ profiles }) => {
  const { localize } = useTranslation();

  return (
    <div className="comparison-profiles">
      <h3 className="comparison-profiles__title">{localize('traveler-test.comparison.results.profiles-comparison')}</h3>
      <div className="comparison-profiles__grid">
        <div className="comparison-profiles__header">
          <span className="comparison-profiles__label">{localize('traveler-test.comparison.friends.name')}</span>
          <span className="comparison-profiles__label">
            {localize('traveler-test.comparison.friends.traveler-type')}
          </span>
        </div>
        <div className="comparison-profiles__list">
          {profiles &&
            profiles.map(({ name, color, traveler_type }) => (
              <div key={uuid.v4()} className="comparison-profiles__profile profile">
                <div className="profile__sign">
                  <span style={{ backgroundColor: color }}>{name?.charAt(0).toUpperCase()}</span>
                </div>
                <span className="profile__name">{name}</span>
                <span className="profile__traveler-type">{traveler_type?.name}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
