import React, { useEffect, useContext } from 'react';
import { useTranslation, useMultilangRoute } from 'utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { PageLoading } from 'modules/page-state';

import * as action from 'redux/test-comparison/actions';
import * as selector from './selectors';
import { GlobalContext } from 'AppContext';

import { TestComparisonHeader } from './components/TestComparisonHeader';
import { TestComparisonInvite } from './components/TestComparisonInvite';
import { ResultsComparison } from './components/ResultsComparison';

import './TestComparisonPage.scss';

export const TestComparisonPage = () => {
  const { auth } = useContext(GlobalContext);
  const { locale } = useTranslation();
  const { redirect, AppRoute, forceRedirect } = useMultilangRoute();
  const dispatch = useDispatch();
  const loading = useSelector(selector.getLoading);
  const comparison = useSelector(selector.getComparison);
  const user = useSelector(selector.getUser);
  const authorized = useSelector(selector.getAuthorized);

  useEffect(() => {
    const unAuthorized = !authorized && authorized !== null;
    if (unAuthorized) {
      forceRedirect(AppRoute.Login);
    }

    return () => unAuthorized && dispatch(action.setAuthorized(null));
  }, [authorized, forceRedirect, dispatch, AppRoute]);

  useEffect(() => {
    if (!comparison) return;
    redirect(AppRoute.TravelerTestComparisonResult, [comparison.uuid]);

    return () => dispatch(action.cleanComparisonDataResponse());
  }, [comparison, AppRoute, redirect, dispatch]);

  useEffect(() => {
    if (!!user) return;

    const lang = locale;
    const guid = auth.getToken();

    dispatch(action.getFriendsRequest({ lang, guid }));
  }, [dispatch, locale, auth, user]);

  if (loading || loading === null) {
    return <PageLoading />;
  }

  return (
    <div className="test-comparison">
      <div className="test-comparison__container">
        <TestComparisonHeader />
        <TestComparisonInvite user={user} />
        <ResultsComparison user={user} />
      </div>
    </div>
  );
};
