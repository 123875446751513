import React from 'react';
import * as uuid from 'uuid';
import { useNavItems } from './useNavItems';

import { NavigationItem } from './components/NavigationItem';

import './MyProfileNavigation.scss';

export const MyProfileNavigation = ({ user }) => {
  const { navItems } = useNavItems(user?.personality_test?.uuid);

  return (
    <div className="my-profile__navigation navigation">
      {navItems.map(({ icon, text, onclick }) => (
        <NavigationItem key={uuid.v4()} icon={icon} text={text} onclick={onclick}/>
      ))}
    </div>
  );
};
