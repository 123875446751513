import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as selector from './selectors';
import * as action from 'redux/user/actions';

export const useMe = (locale, token) => {
  const dispatch = useDispatch();
  const me = useSelector(selector.getMe);
  const loading = useSelector(selector.getLoading);

  const refreshMe = useCallback(() => {
    const guid = token;
    const lang = locale;

    dispatch(action.getMeRequest({ guid, lang }));
  }, [token, locale, dispatch]);

  useEffect(() => {
    if (me) return;

    refreshMe();
  }, [me, refreshMe]);

  return { me, refreshMe, loading };
};
