import React from 'react';

import './NavigationItem.scss';

export const NavigationItem = ({ icon, text, onclick }) => {

  return (
    <div onClick={onclick} className="my-profile__navigation-item navigation-item">
      <div className="navigation-item__icon-wrapper">
        <img src={icon} alt="navigation item" className="navigation-item__icon" />
      </div>
      <h2 className="navigation-item__text">{text}</h2>
    </div>
  );
};
