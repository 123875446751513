import { app } from './app/reducer';
import { user } from './user/reducer';
import { travelerTest } from './traveler-test/reducer';
import { testComparison } from './test-comparison/reducer';
import { startTrip } from './start-trip/reducer';

export const reducers = {
  app,
  user,
  travelerTest,
  testComparison,
  startTrip,
};
