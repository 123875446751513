import { profileSettingsValidator } from '../validators/profile-settings.validator';

export const fields = {
  name: 'name',
  email: 'email',
  marketing_consent: 'marketing_consent',
  password: 'password',
  new_password: 'new_password',
  new_password_confirmation: 'new_password_confirmation',
};

export const profileSettingsSchema = (initialValues) => ({
  initialValues: {
    [fields.name]: initialValues?.name ?? 'unknown',
    [fields.email]: initialValues?.email ?? 'unknown@gmail.com',
    [fields.marketing_consent]: initialValues?.marketing_consent ?? true,
    [fields.password]: '',
    [fields.new_password]: '',
    [fields.new_password_confirmation]: '',
  },
  validationSchema: profileSettingsValidator,
  displayName: 'profileSettingsForm',
});
