import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AppRoute } from 'const';

export const useMultilangRoute = () => {
  const { formatMessage, locale } = useIntl();
  const { push } = useHistory();

  const createURL = (route, params = null, withDomain = false) => {
    const localizedRoute = formatMessage({ id: route });
    const path = localizedRoute?.replace(/:.+/g, '');
    const search = params?.join('/') ?? '';
    const domain = withDomain ? window.location.origin + '/' : null;

    const url = `${domain ?? '/'}${locale}${path}${search}`;

    return url;
  };

  const redirect = (route, params) => {
    const url = createURL(route, params);
    push(url);
  };

  const forceRedirect = (route) => {
    const url = createURL(route, null, true);
    window.location.replace(url);
  };

  return { redirect, forceRedirect, createURL, AppRoute };
};
