import React from 'react';
import * as uuid from 'uuid';
import { useTranslation, useMultilangRoute } from 'utils/hooks';

import './RegisterCTA.scss';

import CompareIcon from 'assets/CTA/compare.png';
import SaveIcon from 'assets/CTA/save.png';
import AccessIcon from 'assets/CTA/easy-access.png';

import GoogleIcon from 'assets/CTA/google.svg';
import FacebookIcon from 'assets/CTA/facebook-letter.svg';

const createBenefit = (text, icon) => ({ text, icon });

const googleLink = '/api/auth/google?type=web';
const facebookLink = '/api/auth/facebook?type=web';

export const RegisterCTA = () => {
  const { localize } = useTranslation();
  const { AppRoute, forceRedirect, createURL } = useMultilangRoute();
  const _domain = window.location.origin;
  const loginUrl = createURL(AppRoute.Login, null, true);

  const benefits = [
    createBenefit(localize('traveler-test.result.CTA.compare-with-friends'), CompareIcon),
    createBenefit(localize('traveler-test.result.CTA.save-packing'), SaveIcon),
    createBenefit(localize('traveler-test.result.CTA.easy-access'), AccessIcon),
  ];

  const signUp = {
    google: localize('traveler-test.result.CTA.sign-up-google'),
    facebook: localize('traveler-test.result.CTA.sign-up-facebook'),
    classic: localize('traveler-test.result.CTA.sign-up-classic'),
  };

  const onClassicRedirect = () => {
    forceRedirect(AppRoute.SignUp);
  };

  return (
    <div className="register-CTA">
      <div className="register-CTA__header">
        <h3 className="register-CTA__title">{localize('traveler-test.result.CTA.save-test')}</h3>
        <h2 className="register-CTA__subtitle">{localize('traveler-test.result.CTA.signup-subtitle')}</h2>
      </div>
      <div className="register-CTA__benefits">
        {benefits.map(({ text, icon }) => (
          <div key={uuid.v4()} className="register-CTA__benefit">
            <img src={icon} alt="benefit" className="register-CTA__benefit-icon" />
            <span className="register-CTA__label">{text}</span>
          </div>
        ))}
      </div>
      <div className="register-CTA__sign-up">
        <a href={`${_domain}${googleLink}`} className="register-CTA__button register-CTA__button--google">
          <img src={GoogleIcon} alt="google" className="register-CTA__social" />
          <span className="register-CTA__sign-up-text">{signUp.google}</span>
        </a>
        <a href={`${_domain}${facebookLink}`} className="register-CTA__button register-CTA__button--facebook">
          <img src={FacebookIcon} alt="facebook" className="register-CTA__social" />
          <span className="register-CTA__sign-up-text">{signUp.facebook}</span>
        </a>
        <div onClick={onClassicRedirect} className="register-CTA__button register-CTA__button--classic">
          <span className="register-CTA__sign-up-text">{signUp.classic}</span>
        </div>
      </div>
      <div className="register-CTA__sign-in">
        <span className="register-CTA__account-exists">
          {localize('traveler-test.result.CTA.already-have-account')}
        </span>
        <a href={loginUrl} className="register-CTA__login">
          {localize('traveler-test.result.CTA.login')}
        </a>
      </div>
    </div>
  );
};
