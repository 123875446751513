import React from 'react';

import { LandingHeader } from './components/LandingHeader';

import './LandingPage.scss';

export const LandingPage = () => {
  return (
    <div className="landing">
      <LandingHeader />
    </div>
  );
};
