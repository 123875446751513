import BargainHunterIcon from 'assets/traveler-test-icons/bargain_hunter.svg';
import CityLifeIcon from 'assets/traveler-test-icons/city_life.svg';
import CreativeInitiatorIcon from 'assets/traveler-test-icons/creative_initiator.svg';
import CultureFanIcon from 'assets/traveler-test-icons/culture_fan.svg';
import FestivalIcon from 'assets/traveler-test-icons/festival.svg';
import NavigatorIcon from 'assets/traveler-test-icons/navigator.svg';
import OrganiserIcon from 'assets/traveler-test-icons/organiser.svg';
import PhotographerIcon from 'assets/traveler-test-icons/photographer.svg';
import PlannerIcon from 'assets/traveler-test-icons/planner.svg';
import SportIcon from 'assets/traveler-test-icons/sport.svg';
import SurprisesIcon from 'assets/traveler-test-icons/surprises.svg';
import TimeKeeperIcon from 'assets/traveler-test-icons/time_keeper.svg';

export const icons = {
  bargain_hunter: BargainHunterIcon,
  city_life: CityLifeIcon,
  creative_initiator: CreativeInitiatorIcon,
  culture_fan: CultureFanIcon,
  festival: FestivalIcon,
  navigator: NavigatorIcon,
  organiser: OrganiserIcon,
  photographer: PhotographerIcon,
  planner: PlannerIcon,
  sport: SportIcon,
  surprises: SurprisesIcon,
  time_keeper: TimeKeeperIcon,
};
