import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as action from 'redux/user/actions';
import * as selector from './selectors';

export const usePersonalityTestResults = (locale, token) => {
  const dispatch = useDispatch();
  const personalityResults = useSelector(selector.getResults);

  const refreshPersonalityResults = useCallback(() => {
    const guid = token;
    const lang = locale;

    dispatch(action.personalityResultsRequest({ guid, lang }));
  }, [token, locale, dispatch]);

  useEffect(() => {
    if (personalityResults) return;
    refreshPersonalityResults();
  }, [refreshPersonalityResults, personalityResults]);

  return { personalityResults, refreshPersonalityResults };
};
