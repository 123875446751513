import React from 'react';
import * as uuid from 'uuid';

import './StrengthsSwiperItem.scss';
import StrengthIcon from 'assets/traveler-test/castle.svg';

export const StrengthsSwiperItem = ({ label, text, icon }) => {
  return (
    <div key={uuid.v4()} className="strengths-swiper__container">
      <div className="strengths-swiper__item strengths-item">
        <div className="strengths-item__header">
          <img src={icon ?? StrengthIcon} alt="strength" className="strengths-item__icon" />
          <h2 className="strengths-item__label">{label}</h2>
        </div>
        <p className="strengths-item__text">{text}</p>
      </div>
    </div>
  );
};
