import React, { useRef, useState } from 'react';
import * as uuid from 'uuid';
import { debounce } from 'lodash';

import { Link } from 'react-scroll';

import './Anchor.scss';
import { useEffect } from 'react';

const HIDE_DELAY = 1500;

export const Anchor = ({ anchorConfig }) => {
  const anchorRef = useRef();

  const [isScroll, setIsScroll] = useState(false);
  const [activeLink, setActiveLink] = useState(-1);
  const [activeSublink, setActiveSublink] = useState(-1);

  const initializeLinks = () => {
    // array [{href, idx}, {href, idx}]
    const _links = anchorConfig.map(({ href }, idx) => ({ href, idx }));

    // array [{href, idx}, {href, idx}]
    const _sublinks = anchorConfig.reduce((_links, { sublinks }) => {
      const _sublinks = sublinks?.map(({ href }, idx) => ({ href, idx }));
      _sublinks && _links.push(..._sublinks);

      return _links;
    }, []);

    const reduceLinks = (elems) => {
      return elems?.reduce((array, { href, idx }) => {
        const el = document.querySelector(`#${href}`);
        if (!el) return array;
        array.push({ el, idx });
        return array;
      }, []);
    };

    const links = reduceLinks(_links);
    const sublinks = reduceLinks(_sublinks);

    return { links, sublinks };
  };

  const { links, sublinks } = initializeLinks();

  const onSetActiveLink = (idx) => {
    setActiveLink(idx);
    setActiveSublink(-1);
  };

  const onWindowScroll = () => {
    const toLinksWithPosition = (_links) => {
      return _links.reduce((array, { el, idx }) => {
        array.push({
          el,
          idx,
          top: el.getBoundingClientRect().top - 50,
        });

        return array;
      }, []);
    };

    const linksPosition = toLinksWithPosition(links);
    const sublinksPosition = toLinksWithPosition(sublinks);

    const _activeLink = linksPosition.reverse().find(({ top }) => top <= 0);
    const _activeSublink = sublinksPosition.reverse().find(({ top }) => top <= 0);

    setActiveLink(_activeLink?.idx ?? -1);
    setActiveSublink(_activeSublink?.idx ?? -1);
  };

  let anchorChangeStateTimeout = null;
  const hideAnchor = () => {
    isScroll && setIsScroll(false);
    clearTimeout(anchorChangeStateTimeout);
  };

  const onAnchorChangeState = () => {
    if (!isScroll) setIsScroll(true);

    clearTimeout(anchorChangeStateTimeout);
    anchorChangeStateTimeout = setTimeout(hideAnchor, HIDE_DELAY);
  };

  useEffect(() => {
    if (!anchorRef?.current) return;
    isScroll ? anchorRef.current.classList.add('open-anchor') : anchorRef.current.classList.remove('open-anchor');
  }, [isScroll, anchorRef]);

  useEffect(() => {
    const scrollHandler = () => debounce(onWindowScroll, 50);
    const anchorStateHandler = () => debounce(onAnchorChangeState, 10);

    window.addEventListener('scroll', scrollHandler());
    window.addEventListener('scroll', anchorStateHandler());

    initializeLinks();

    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('scroll', anchorStateHandler);
    };
  });

  const renderSublink = (idx, title, href) => {
    const isActive = idx === activeSublink;
    const className = `anchor__sublink ${isActive ? 'anchor__sublink--active' : ''}`;

    const exists = sublinks?.some(({ el }) => el?.id === href);

    return exists ? (
      <Link onClick={() => setActiveSublink(idx)} key={uuid.v4()} to={href} duration={250} smooth>
        <div className={className}>{title}</div>
      </Link>
    ) : null;
  };

  const renderLink = (idx, title, href, sublinks) => {
    const isActive = idx === activeLink;
    const className = `anchor__link ${isActive ? 'anchor__link--active' : ''}`;

    const exists = links?.some(({ el }) => el?.id === href);

    return exists ? (
      <React.Fragment key={uuid.v4()}>
        <div className={className}>
          <Link onClick={() => onSetActiveLink(idx)} to={href} duration={250} smooth>
            {title}
          </Link>
        </div>
        {sublinks && (
          <div className="anchor__sublinks">
            {sublinks.map(({ title, href }, idx) => renderSublink(idx, title, href))}
          </div>
        )}
      </React.Fragment>
    ) : null;
  };

  return (
    <div className="anchor-container">
      <div ref={anchorRef} className="anchor hide-anchor">
        {anchorConfig?.map(({ title, href, sublinks }, idx) => renderLink(idx, title, href, sublinks))}
      </div>
    </div>
  );
};
