import { formalizeReturn } from './utils';

export const startTrip = async ({
  guid,
  lang,
  origin,
  destination_name,
  destination_country_code,
  date_from,
  date_to,
}) => {
  const response = await fetch(`/api/packing-lists/new`, {
    method: 'POST',
    body: JSON.stringify({ origin, destination_name, destination_country_code, date_from, date_to }),
    headers: {
      'Content-Type': 'application/json',
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};
