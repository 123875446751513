import React, { useContext } from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks';
import { useSelector, useDispatch } from 'react-redux';

import * as selector from './selectors';
import * as action from 'redux/test-comparison/actions';
import { GlobalContext } from 'AppContext';

import { Button } from 'antd';

import './SelectedFriendsList.scss';
import CrossIcon from 'assets/cross.svg';

export const SelectedFriendsList = ({ user }) => {
  const { auth } = useContext(GlobalContext);
  const { localize, locale } = useTranslation();

  const selectedFriends = useSelector(selector.getSelectedFriends);
  const dispatch = useDispatch();

  const onDeselectFriend = (friend) => {
    dispatch(action.deselectFriend(friend));
  };

  const onStartComparisonClick = () => {
    const friendsIds = selectedFriends.map(({ personality_test }) => personality_test.uuid);
    friendsIds.push(user?.personality_test.uuid);

    const guid = auth.getToken();
    const lang = locale;

    dispatch(action.sendComparisonDataRequest({ friendsIds, guid, lang }));
  };

  return (
    <div className="results-comparison__selected-friends selected-friends">
      <h2 className="selected-friends__title">{localize('traveler-test.comparison.friends.comparison')}</h2>
      <div className="selected-friends__list">
        <div className="selected-friends__header">
          <span className="selected-friends__username">{`${user?.name} (${localize(
            'traveler-test.comparison.friends.you'
          )})`}</span>
        </div>
        {selectedFriends?.map((friend) => (
          <div key={uuid.v4()} className="selected-friends__friend friend">
            <span className="friend__name">{friend.name}</span>
            <div onClick={() => onDeselectFriend(friend)} className="friend__remove">
              <img src={CrossIcon} alt="cross" className="friend__remove-icon" />
            </div>
          </div>
        ))}
        <div className="selected-friends__start-comparison-wrapper">
          <Button
            id="compare-action-button"
            onClick={onStartComparisonClick}
            disabled={!selectedFriends?.length}
            type="primary">
            <span>{localize('traveler-test.comparison.friends.compare-results')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
