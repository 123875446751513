import React from 'react';

import { AppContext } from './AppContext';
import { AppRoute, AppLanguage } from './const';
import { LocalizedRouter, LocalizedSwitch, appStrings } from './modules/i18n/';
import { ScrollToTop } from './modules/navigation';
import { AppLayout } from './modules/layout';
import { Route } from 'react-router-dom';

import {
  MyProfilePage,
  ProfileSettings,
  TravelerTypePage,
  TravelerTypeResultPage,
  TravelerTypeLandingPage,
  TestComparisonPage,
  ComparisonResultsPage,
  StartTripPage,
  LandingPage,
} from './pages';

import './App.less';

const App = () => {
  return (
    <LocalizedRouter appStrings={appStrings} defaultLanguage={AppLanguage.Polish}>
      <AppContext>
        <AppLayout>
          <ScrollToTop />
          <LocalizedSwitch>
            <Route exact path={AppRoute.ProfileSettings} component={ProfileSettings} />
            <Route exact path={AppRoute.MyProfile} component={MyProfilePage} />
            <Route exact path={AppRoute.Home} component={LandingPage} />

            <Route exact path={AppRoute.TravelerTestLanding} component={TravelerTypeLandingPage} />
            <Route exact path={AppRoute.TravelerTestCompare} component={TestComparisonPage} />
            <Route exact path={AppRoute.TravelerTestComparisonResult} component={ComparisonResultsPage} />
            <Route exact path={AppRoute.TravelerTest} component={TravelerTypePage} />
            <Route exact path={AppRoute.TravelerTestResult} component={TravelerTypeResultPage} />

            <Route exact path={AppRoute.StartTrip} component={StartTripPage} />
          </LocalizedSwitch>
        </AppLayout>
      </AppContext>
    </LocalizedRouter>
  );
};

export default App;
