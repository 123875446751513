import * as type from './types';

const initialState = {
  toast: null,
};

export const app = (state = initialState, action) => {
  switch (action.type) {
    case type.TOAST_MESSAGE:
      return {
        ...state,
        toast: action.payload,
      };

    default:
      return state;
  }
};
