import React, { useContext } from 'react';
import { MyProfileHeader } from './components/MyProfileHeader';
import { MyProfileNavigation } from './components/MyProfileNavigation';
import { GlobalContext } from 'AppContext';

import './MyProfilePage.scss';

export const MyProfilePage = () => {
  const { personalityResults } = useContext(GlobalContext);

  return (
    <div className="my-profile">
      <div className="my-profile__container">
        <MyProfileHeader username={personalityResults?.name} />
        <MyProfileNavigation user={personalityResults} />
      </div>
    </div>
  );
};
