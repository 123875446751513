import React from 'react';
import { ShareResults } from 'pages/apps/personality-test/components/ShareResult';
import { RegisterCTA } from '../components/RegisterCTA';

export const testCreator = ({ testGuid, localize, url, match }) => {
  const role = 'testCreator';

  const renderShareSection = () => (
    <ShareResults title={localize('traveler-test.result.CTA.share')} url={url} uuid={testGuid} />
  );

  const renderCompareSection = () => <RegisterCTA />;

  return { role, renderShareSection, renderCompareSection, match };
};
