import React, { useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'utils/hooks';
import { useFormik } from 'formik';
// import { geocode } from './geocode';

import { startTripSchema, fields } from 'utils/form-schemas/start-trip.schema';
import * as selector from './selectors';
import * as action from 'redux/start-trip/actions';
import { GlobalContext } from 'AppContext';

import { SearchDestination } from './components/SearchDestination';
import { DateRangePicker } from './components/DateRangePicker';
import { RadioGroup } from './components/RadioGroup';
import { DownloadApp } from 'pages/apps/general/components/DownloadApp';

import './StartTripForm.scss';

const convertGeolocation = ({ city, country, countryCode }) => ({
  city,
  country,
  countryCode,
});

// window.navigator.geolocation.getCurrentPosition(({ coords }) => {
//   console.log('coords', coords);
//   const { latitude, longitude } = coords;
//   const location = new window.google.maps.LatLng(latitude, longitude);
//   geocode({ location }, (res) => console.log(res));
// });

export const StartTripForm = () => {
  const { auth } = useContext(GlobalContext);
  const dispatch = useDispatch();
  const userGeolocation = useSelector(selector.getUserGeolocation);

  const { localize, locale } = useTranslation();
  const { values, errors, handleChange, handleSubmit, setFieldValue, validateForm } = useFormik(startTripSchema());

  const disabled = useMemo(() => Object.keys(errors).length > 0, [errors]);
  const origin = useMemo(() => userGeolocation && convertGeolocation(userGeolocation), [userGeolocation]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    dispatch(action.getUserGeolocationRequest());
  }, [dispatch]);

  const onSubmit = () => {
    const data = {
      origin,
      destination_name: values?.destination,
      destination_country_code: values?.countryCode,
      date_from: values?.startDate,
      date_to: values?.endDate,
    };

    const lang = locale;
    const guid = auth.getToken();

    dispatch(action.startTripRequest({ ...data, guid, lang }));
  };

  return (
    <form onSubmit={handleSubmit} className="start-trip-form">
      <SearchDestination
        setFieldValue={setFieldValue}
        destination={fields.destination}
        countryCode={fields.countryCode}
      />
      <DateRangePicker
        startValue={values?.startDate}
        endValue={values?.endDate}
        startName={fields.startDate}
        endName={fields.endDate}
        startPlaceholder={localize('packing.start-trip.departure')}
        endPlaceholder={localize('packing.start-trip.return')}
        setFieldValue={setFieldValue}
      />
      <RadioGroup name={fields.gender} value={values?.gender} onChange={handleChange} />
      <DownloadApp
        buttonDisabled={disabled}
        buttonText={localize('packing.start-trip.start-packing')}
        onButtonClick={onSubmit}
      />
    </form>
  );
};
