import React from 'react';
import * as uuid from 'uuid';
import { withColors } from 'pages/utils/withColors';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'utils/hooks';

import * as selector from './selectors';
import * as action from 'redux/test-comparison/actions';

import './FriendsList.scss';

export const FriendsList = () => {
  const { localize } = useTranslation();
  const friends = withColors(useSelector(selector.getFriends), 1);
  const selectedFriends = useSelector(selector.getSelectedFriends);
  const dispatch = useDispatch();

  const onSelectFriend = (friend) => {
    const selected = selectedFriends?.some(({ id }) => id === friend.id);
    if (!friend.personality_test || selected) return;
    dispatch(action.selectFriend(friend));
  };

  const renderAddButton = (friend) => {
    const selected = selectedFriends?.some(({ id }) => id === friend.id);
    const modifier = friend.personality_test && !selected ? '' : 'friend__add-button--disabled';
    const className = `friend__add-button ${modifier}`;

    return (
      <div onClick={() => onSelectFriend(friend)} className={className}>
        <span>{localize('traveler-test.comparison.friends.add')}</span>
      </div>
    );
  };

  return (
    <div className="results-comparison__friends-list friends-list">
      <h3 className="friends-list__title">{localize('traveler-test.comparison.friends.friends-list')}</h3>
      <div className="friends-list__grid">
        <div className="friends-list__grid-head">
          <span className="friends-list__grid-title">{localize('traveler-test.comparison.friends.name')}</span>
          <span className="friends-list__grid-title">{localize('traveler-test.comparison.friends.traveler-type')}</span>
          <span className="friends-list__grid-title">{localize('traveler-test.comparison.friends.comparison')}</span>
        </div>
        <div className="friends-list__list">
          {friends?.map((friend) => (
            <div key={uuid.v4()} className="friends-list__friend friend">
              <div className="friend__sign">
                <span style={{ backgroundColor: friend.color }}>{friend.name.charAt(0).toUpperCase()}</span>
              </div>
              <span className="friend__name">{friend.name}</span>
              <span className="friend__traveler-type">{friend?.personality_test?.traveler_type}</span>
              <div className="friend__add-button-wrapper">{renderAddButton(friend)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
