import React, { useState } from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks';

import { Button } from 'antd';
import { DestinationModal } from '../DestinationModal';

import './Destinations.scss';

export const Destinations = ({ destinations }) => {
  const [showModal, setShowModal] = useState(false);
  const [destination, setDestination] = useState(null);
  const { localize } = useTranslation();

  const onLearnMoreClick = (dest) => {
    setShowModal(true);
    setDestination(dest);
  };

  return destinations ? (
    <>
      <DestinationModal visible={showModal} setVisible={setShowModal} destination={destination} />
      <div id="destinations" className="traveler-result__destinations destinations">
        <div className="destinations__wrapper">
          <h3 className="destinations__title">
            {localize('traveler-test.result.destinations.recommended-destination')}
          </h3>
          <div className="destinations__list">
            {destinations?.map((dest) => (
              <div key={uuid.v4()} className="destinations__destination destination">
                <img src={dest?.image} alt="destination" className="destination__img" />
                <h4 className="destination__name">{dest.name}</h4>
                <div className="destination__hidden">
                  <span className="destination__description">{dest.description_short}</span>
                  <div className="destination__learn-more">
                    <Button
                      type="primary"
                      id="destination-learn-more"
                      data-destination-id={dest.id}
                      data-destination-name={dest.name}
                      onClick={() => onLearnMoreClick(dest)}>
                      {localize('traveler-test.result.destinations.learn-more')}
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  ) : null;
};
