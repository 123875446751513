import React, { useEffect, useMemo, useContext } from 'react';
import { useTranslation, useMultilangRoute } from 'utils/hooks';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { withColors } from 'pages/utils/withColors';
import * as selector from './selectors';
import * as action from 'redux/test-comparison/actions';
import { GlobalContext } from 'AppContext';

import { PageLoading } from 'modules/page-state';
import { Profiles } from './components/Profiles';
import { PreferencesComparison } from './components/PreferencesComparison';
import { TasksComparison } from './components/TasksComparison';
import { ComparisonSummary } from './components/ComparisonSummary';
import { GetApp } from 'pages/apps/personality-test/components/GetApp';
import { ShareResults } from 'pages/apps/personality-test/components/ShareResult';

import './ComparisonResultsPage.scss';

export const ComparisonResultsPage = () => {
  const params = useParams();
  const { auth } = useContext(GlobalContext);
  const { localize, locale } = useTranslation();
  const { AppRoute, createURL } = useMultilangRoute();

  const dispatch = useDispatch();
  const comparison = useSelector(selector.getComparison);
  const loading = useSelector(selector.getLoading);

  const profiles = useMemo(() => withColors(comparison?.people), [comparison]);
  const summary = useMemo(() => comparison?.summary, [comparison]);
  const destination = useMemo(() => comparison?.recommended_destinaton, [comparison]); // FIX API typo
  const comparison_uuid = params.uuid;
  const url = createURL(AppRoute.TravelerTestComparisonResult, [comparison_uuid], true);

  useEffect(() => {
    if (comparison) return;

    const guid = auth.getToken();
    const lang = locale;

    dispatch(action.getComparisonRequest({ comparison_uuid, guid, lang }));
  }, [dispatch, params, comparison, auth, locale, comparison_uuid]);

  if (loading || loading === null) {
    return <PageLoading />;
  }

  return (
    <div className="comparison-results">
      <Profiles profiles={profiles} />
      <PreferencesComparison profiles={profiles} />
      <TasksComparison profiles={profiles} />
      <ComparisonSummary profiles={profiles} summary={summary} destination={destination} />
      <ShareResults title={localize('traveler-test.comparison.share-comparison')} url={url} />
      <GetApp />
    </div>
  );
};
