import React from 'react';

import './TravelerFeature.scss';
import TravelerFeatureIcon from 'assets/traveler-test/traveler-feature-mocked.svg';

export const TravelerFeature = ({ title, icon }) => {
  return (
    <div className="traveler-feature">
      <div className="traveler-feature__container">
        <img src={icon ?? TravelerFeatureIcon} alt="traveler feature icon" className="traveler-feature__icon" />
        <h3 className="traveler-feature__title">{title}</h3>
      </div>
    </div>
  );
};
