import React from 'react';

import { LandingHeader } from './components/LandingHeader';
import { LandingStrengths } from './components/LandingStrengths';
import { LandingComparePreferences } from './components/LandingComparePreferences';
import { ShareTasks } from './components/ShareTasks';

import './TravelerTypeLandingPage.scss';

export const TravelerTypeLandingPage = () => {
  return (
    <div className="traveler-type-landing">
      <LandingHeader />
      <LandingStrengths />
      <LandingComparePreferences />
      <ShareTasks />
    </div>
  );
};
