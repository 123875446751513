import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';
import { useMultilangRoute } from 'utils/hooks/app/useMultilangRoute';
import { AppRoute } from 'const';

import './MyProfileHeader.scss';
import SettingsIcon from 'assets/settings.svg';

export const MyProfileHeader = ({ username }) => {
  const { localize } = useTranslation();
  const { redirect } = useMultilangRoute();

  const _username = `${username ? `, ${username}` : ''}`;
  const greeting = `${localize('my-profile.greeting')}${_username}!`;

  const redirectToSettings = () => {
    redirect(AppRoute.ProfileSettings);
  };

  return (
    <div className="my-profile__header">
      <h3 className="my-profile__greeting">{greeting}</h3>
      <div onClick={redirectToSettings} className="my-profile__settings">
        <span className="my-profile__settings-text">{localize('my-profile.settings')}</span>
        <img src={SettingsIcon} alt="settings" className="my-profile__settings-icon" />
      </div>
    </div>
  );
};
