import * as type from './types';

export const selectFriend = (friend) => ({
  type: type.SELECT_FRIEND,
  payload: friend,
});

export const deselectFriend = (friend) => ({
  type: type.DESELECT_FRIEND,
  payload: friend,
});

export const setLoading = (loading) => ({
  type: type.SET_LOADING,
  payload: loading,
});

export const setAuthorized = (authorized) => ({
  type: type.SET_AUTHORIZED,
  payload: authorized,
});

// --- API ---
export const getFriendsRequest = ({ lang, guid }) => ({
  type: type.GET_FRIENDS_REQUEST,
  payload: { lang, guid },
});

export const sendComparisonDataRequest = ({ friendsIds, guid, lang }) => ({
  type: type.SEND_COMPARISON_DATA_REQUEST,
  payload: { friendsIds, guid, lang },
});

export const getComparisonRequest = ({ comparison_uuid, guid, lang }) => ({
  type: type.GET_COMPARISON_REQUEST,
  payload: { comparison_uuid, guid, lang },
});

export const getFriendsResponse = (response) => ({
  type: type.GET_FRIENDS_RESPONSE,
  payload: response,
});

export const sendComparisonDataResponse = (response) => ({
  type: type.SEND_COMPARISON_DATA_RESPONSE,
  payload: response,
});

export const getComparisonResponse = (response) => ({
  type: type.GET_COMPARISON_RESPONSE,
  payload: response,
});

export const cleanComparisonDataResponse = () => ({
  type: type.CLEAN_COMPARISON_DATA_RESPONSE,
});
