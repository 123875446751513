import * as type from './types';

const initialState = {
  loading: null,
  userGeolocation: null,
  startTripResponse: null,
};

export const startTrip = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case type.GET_USER_GEOLOCATION_REQUEST:
      return state;

    case type.START_TRIP_REQUEST:
      return state;

    case type.GET_USER_GEOLOCATION_RESPONSE:
      return {
        ...state,
        userGeolocation: action.payload,
      };

    case type.START_TRIP_RESPONSE:
      return {
        ...state,
        startTripResponse: action.payload,
      };

    default:
      return state;
  }
};
