export const pl = {
  'routes.traveler-test.landing': '/typ-podroznika',
  'routes.traveler-test.start': '/typ-podroznika/test',
  'routes.traveler-test.invite': '/typ-podroznika/zaproszenie/:guid',
  'routes.traveler-test.compare': '/typ-podroznika/porownaj',
  'routes.traveler-test.comparison-results': '/typ-podroznika/porownaj/wynik/:uuid',
  'routes.traveler-test.result': '/typ-podroznika/wynik/:personality_test_uuid',
  'routes.travel-guides': '/travel-guides',

  'traveler-test.title': 'Typ podróżnika - darmowy test!',
  'traveler-test.takes-time': 'Zajmuje mniej niż 10 minut',
  'traveler-test.without-registration': 'Nie wymaga rejestracji',
  'traveler-test.next-button': 'Dalej',
  'traveler-test.title-lowest': 'Nie zgadzam się',
  'traveler-test.title-highest': 'Zgadzam się',

  // Test comparison
  'traveler-test.comparison.compare-profile': 'Porównaj swoje preferencje z bliskimi',
  'traveler-test.comparison.header.subtitle':
    'Aplikacja rozdzieli zadania na waszą następną podróż oraz pokaże co Was łączy i różni w podróżowaniu.',

  'traveler-test.comparison.invite.add-friends': 'Dodaj znajomych aby porównać wyniki',
  'traveler-test.comparison.invite.copy-to-clipboard': 'Skopiuj do schowka',
  'traveler-test.comparison.invite.copied': 'Skopiowane!',

  'traveler-test.comparison.friends.friends-list': 'Lista Twoich znajomych',
  'traveler-test.comparison.friends.name': 'Imię',
  'traveler-test.comparison.friends.traveler-type': 'Typ podróżnika',
  'traveler-test.comparison.friends.add': 'Dodaj',
  'traveler-test.comparison.friends.you': 'Ty',
  'traveler-test.comparison.friends.comparison': 'Porównanie',
  'traveler-test.comparison.friends.compare-results': 'Porównaj wyniki',

  'traveler-test.comparison.share-comparison': 'Udostępnij porównanie',

  'traveler-test.comparison.steps.send-link': 'Wyślij link wybranej osobie',
  'traveler-test.comparison.steps.ask-friend': 'Poczekaj aż założy konto i wypełni test',
  'traveler-test.comparison.steps.add-friend': 'Odśwież swoją listę znajomych',

  // Test comparison results
  'traveler-test.comparison.results.profiles-comparison': 'Porównanie profili',
  'traveler-test.comparison.results.your-preferences': 'Oto wasze preferencje!',
  'traveler-test.comparison.results.spend-time': 'Jak  lubicie spędzać czas',
  'traveler-test.comparison.results.what-environment': 'Jakie otoczenie preferujecie',
  'traveler-test.comparison.results.your-risk-attitude': 'Wolisz bezpieczeństwo czy ryzyko',
  'traveler-test.comparison.results.plan-trips': 'Jak planujecie wyjazdy',

  'traveler-test.comparison.results.tasks.how-you-handle-tasks': 'Jak sobie radzicie z zadaniami',
  'traveler-test.comparison.results.tasks.preferred-location': 'Wybór lokalizacji',
  'traveler-test.comparison.results.tasks.packing': 'Pakowanie',
  'traveler-test.comparison.results.tasks.accomodation': 'Rezerwacja noclegu',
  'traveler-test.comparison.results.tasks.photographer': 'Robienie zdjęć',
  'traveler-test.comparison.results.tasks.organiser': 'Organizacja',
  'traveler-test.comparison.results.tasks.restaurant_choice': 'Wybór restauracji',
  'traveler-test.comparison.results.location.mountains': 'Góry',
  'traveler-test.comparison.results.location.water': 'Woda',
  'traveler-test.comparison.results.location.city': 'Miasto',
  'traveler-test.comparison.results.location.festival': 'Festiwal',

  'traveler-test.comparison.results.summary.title': 'Podsumowanie',
  'traveler-test.comparison.results.summary.subtitle': 'Podział zadań',

  'traveler-test.comparison.results.summary.where-should-you-go': 'Gdzie powinniście się wybrać:',

  'traveler-test.comparison.results.see-results': 'Zobacz swoje wyniki',
  'traveler-test.comparison.results.retake-test': 'Wypełnij ponownie test',

  // Traveler test result page
  'traveler-test.result.traveler-specialization': 'Specjalizacje',
  'traveler-test.result.traveler-skills': 'Umiejętności',
  'traveler-test.result.traveler-profile': 'Profil podróżnika',

  'traveler-test.result.destination': 'Cel podróży',
  'traveler-test.result.destination.tooltip':
    'Ta cecha wskazuje czy bardziej cenisz sobie próbowanie nowych rzeczy czy wygodę.',
  'traveler-test.result.location': 'Lokalizacja',
  'traveler-test.result.location.tooltip':
    'Ta cecha opisuje, gdzie się najlepiej czujesz i gdzie najczęściej jeździsz.',
  'traveler-test.result.approach': 'Podejście',
  'traveler-test.result.approach.tooltip': 'Ta cecha opisuje Twój stosunek do podejmowanie ryzyka podczas podróży.',
  'traveler-test.result.planning': 'Planowanie',
  'traveler-test.result.planning.tooltip':
    'Ta cecha wskazuje jak najczęściej podchodzisz do planowania swoich wyjazdów.',

  'traveler-test.result.learning': 'Nauka',
  'traveler-test.result.leisure': 'Odpoczynek',
  'traveler-test.result.nature': 'Natura',
  'traveler-test.result.city': 'Miasto',
  'traveler-test.result.risk': 'Ryzyko',
  'traveler-test.result.safety': 'Bezpieczeństwo',
  'traveler-test.result.accuracy': 'Dokładność',
  'traveler-test.result.spontaneity': 'Spontaniczność',

  'traveler-test.result.CTA.discover-personality': 'Odkryj swój Typ Podróznika',
  'traveler-test.result.CTA.take-free-test': 'Wypełnij darmowy test',
  'traveler-test.result.CTA.understand-preferences': 'Odkryj swoje preferencje podróżnicze!',
  'traveler-test.result.CTA.take-test': 'Wypełnij darmowy test',
  'traveler-test.result.CTA.share': 'Udostępnij swój wynik',
  'traveler-test.result.CTA.save-test': 'Załóż darmowe konto, aby zapisać swój wynik i porównać go ze znajomymi!',
  'traveler-test.result.CTA.signup-subtitle': 'Jakie możliwości daje Ci posiadanie konta MyLuggage?',
  'traveler-test.result.CTA.compare-with-friends': 'Porównywanie wyników ze znajomymi',
  'traveler-test.result.CTA.save-packing': 'Możliwość zapisania testu i listy',
  'traveler-test.result.CTA.easy-access': 'Łatwy dostęp z dowolnego urządzenia',
  'traveler-test.result.CTA.sign-up-google': 'Zarejestruj się przez Google',
  'traveler-test.result.CTA.sign-up-facebook': 'Zarejestruj się przez Facebook',
  'traveler-test.result.CTA.sign-up-classic': 'Klasyczna rejestracja',
  'traveler-test.result.CTA.already-have-account': 'Masz już konto?',
  'traveler-test.result.CTA.login': 'Zaloguj się',

  'traveler-test.result.traveler-preference': 'Podróżnicze preferencje',
  'traveler-test.result.interesting-facts': 'Ciekawostki',
  'traveler-test.result.traveler-specializations': 'Specjalizacje',

  'traveler-test.result.travel-personality': 'Podróżnicza osobowość',

  'traveler-test.result.travel-planning': 'Planowanie i podejmowanie decyzji',
  'traveler-test.result.collecting-information': 'Zbieranie informacji',
  'traveler-test.result.innovator': 'Innowator',
  'traveler-test.result.clear-planner': 'Klarowny planista',

  'traveler-test.result.compare-preferences': 'Porównaj swoje preferencje z innymi',
  'traveler-test.result.send-survey-to-people': 'Wyślij ankietę wybranym osobom',
  'traveler-test.result.compare-your-results': 'Porównaj wasze wyniki',
  'traveler-test.result.discover-differences-between-you': 'Odkryj dzielące was różnice',
  'traveler-test.result.how-you-plan-trip': 'Jak planujecie wyjazdy',
  'traveler-test.result.how-you-spend-time': 'Jak  lubicie spędzać czas',

  'traveler-test.result.destinations.recommended-destination': 'Rekomendowane destynacje',
  'traveler-test.result.destinations.learn-more': 'Dowiedz się więcej',
  'traveler-test.result.destinations.like': 'Podoba mi się',
  'traveler-test.result.destinations.dislike': 'Nie podoba mi się',
  'traveler-test.result.destinations.plan-trip': 'Zaplanuj podróż',

  'traveler-test.result.compare-preferences-button': 'Porównaj wyniki',

  //Traveler test landing page
  'traveler-test.landing.understand-preferences': 'Zrozum swoje podróżnicze preferencje',
  'traveler-test.landing.find-out-what-enjoy': 'Dowiedz się co sprawia Ci przyjemność, a czego powinieneś unikać.',
  'traveler-test.landing.how-spend-time': 'Jak lubisz spędzać czas?',
  'traveler-test.landing.what-environment': 'Jakie otoczenie preferujesz?',
  'traveler-test.landing.how-risky': 'Wolisz bezpieczeństwo czy ryzyko?',
  'traveler-test.landing.how-you-plan-travel': 'Jak  planujesz wyjazdy?',
  'traveler-test.landing.take-free-test': 'Wypełnij darmowy test',
  'traveler-test.landing.go-to-test': 'Przejdź do testu',
  'traveler-test.landing.take-test': 'Wypełnij test',

  'traveler-test.landing.know-strengths': 'Poznaj swoje mocne strony',
  'traveler-test.landing.how-get-most-of-trip': 'Sprawdź, jak czerpać maksimum z podróży!',
  'traveler-test.landing.activities-that-pleasant': 'Aktywności, które sprawiają Ci przyjemność:',
  'traveler-test.landing.specializations': 'Specjalizacje',
  'traveler-test.landing.surprise-fan': 'Fan Niespodzianek',
  'traveler-test.landing.bargain-hunter': 'Łowca Okazji',
  'traveler-test.landing.culture-fan': 'Fan Kultury',
  'traveler-test.landing.talents-that-built-travel': 'Talenty, które budują Twoje podróże',
  'traveler-test.landing.organizer': 'Organizator',
  'traveler-test.landing.born-planner': 'Urodzony Planista',
  'traveler-test.landing.photographer': 'Fotograf',
  'traveler-test.landing.accommodation': 'Rezerwacja noclegu',

  'traveler-test.landing.surprise-fan.text':
    'Twoja inwencja i kreatywność są niezastąpione na wyjeździe. Uwielbiasz być zaskakiwany jak i samemu organizować niezapowiedziane atrakcje.',
  'traveler-test.landing.bargain-hunter.text':
    'Wiesz, że rozplanowanie wyjazdu pozwala skorzystać z atrakcyjnych ofert. Masz dryg do wybierania ofert posiadających dobry stosunek ceny do jakości.',
  'traveler-test.landing.culture-fan.text':
    'Chętnie wybierasz cele podróży, które obfitują w różnorodne atrakcje, które mogą nauczyć Cię czegoś nowego, lub dzięki którym możesz zobaczyć ciekawe zabytki i obiekty historyczne.',
  'traveler-test.landing.organizer.text':
    'Często odpowiadasz za organizacje, jednak to nie problem, gdyż świetnie Ci to idzie. Co więcej, sprawia Ci to przyjemność.',
  'traveler-test.landing.born-planner.text':
    'Posiadasz cechę, której mogą pozazdrościć Ci inni podróżnicy - potrafisz bez trudu zorganizować i zaplanować wszystkie najważniejsze aspekty wyjazdu.',
  'traveler-test.landing.photographer.text':
    'To dzięki Tobie po podróży sypią się lajki, serduszka i komentarze! Chcesz uwiecznić jak najwięcej z podróży, tak, by cieszyć się wspomnieniami.',

  'traveler-test.landing.compare-preferences': 'Porównaj swoje preferencje z innymi',
  'traveler-test.landing.use-results-comparison':
    'Skorzystaj z funkcji porównywania wyników i spraw, by grupowe wyjazdy były jeszcze lepsze niż przedtem!',

  'traveler-test.landing.share-tasks': 'Rozdziel zadania na następny wyjazd',
  'traveler-test.landing.see-who-should-take-care':
    'Zobacz, kto powinien znaleźć świetną miejscówkę, a kto przygotuje listę rzeczy do spakowania.',
  'traveler-test.landing.packing': 'Pakowanie',
  'traveler-test.landing.accommodation-booking': 'Rezerwacja noclegu',
  'traveler-test.landing.taking-photos': 'Robienie zdjęć',
};
