import { takeLatest } from 'redux-saga/effects';
import * as type from './types';
import * as saga from './saga';

export const personalityResultsSagaWatcher = [
  takeLatest(type.PERSONALITY_RESULTS_REQUEST, saga.getPersonalityResultsWorker),
  takeLatest(type.GET_ME_REQUEST, saga.getMeWorker),
  takeLatest(type.UPDATE_ME_REQUEST, saga.updateMeWorker),
  takeLatest(type.DELETE_ME_REQUEST, saga.deleteMeWorker),
];
