import { put, call } from 'redux-saga/effects';

import { AppMessage } from 'const/app-messages';

import * as userApi from 'api/user';
import * as api from 'api/start-trip';

import * as actions from './actions';
import * as appAction from 'redux/app/actions';

export function* getUserGeolocationWorker() {
  try {
    const response = yield call(userApi.getUserGeolocation);
    yield put(actions.getUserGeolocationResponse(response));
  } catch {
    // error handling
  }
}

export function* startTripWorker(action) {
  try {
    yield put(actions.setLoading(true));

    const response = yield call(api.startTrip, action.payload);
    switch (response.status) {
      case 200:
        yield put(actions.startTripResponse(response));
        yield put(actions.setLoading(false));
        break;

      default:
        yield put(appAction.toastMessage('error', AppMessage.serverError));
    }
  } catch {
    // error handling
  }
}
