const createUserData = (name, packing, accommodation, photographer) => ({
  name,
  tasks: {
    packing,
    accommodation,
    photographer,
  },
});

export const usersData = [
  createUserData('Mateusz', 82, 66, 48),
  createUserData('Anna', 5, 95, 56),
  createUserData('Jurek', 43, 72, 24),
  createUserData('Karolina', 35, 5, 88),
];
