export const SET_LOADING = 'TRAVELER_TEST/SET_LOADING';
export const START_TEST = 'TRAVELER_TEST/SET_QUESTIONS';
export const SET_PROGRESS = 'TRAVELER_TEST/SET_PROGRESS';
export const SET_ANSWERS = 'TRAVELER_TEST/SET_ANSWERS';
export const SET_ANSWER = 'TRAVELER_TEST/SET_ANSWER';
export const NEXT_PAGE = 'TRAVELER_TEST/NEXT_PAGE';
export const RATE_DESTINATION = 'TRAVELER_TEST/RATE_DESTINATION';

// --- API ---
export const START_TEST_REQUEST = 'TRAVELER_TEST/GET_ALL_QUESTIONS_REQUEST';
export const SEND_ANSWERS_REQUEST = 'TRAVELER_TEST/SEND_ANSWERS_REQUEST';
export const FINISH_TEST_REQUEST = 'TRAVELER_TEST/FINISH_TEST_REQUEST';
export const GET_RESULT_REQUEST = 'TRAVELER_TEST/GET_RESULT_REQUEST';
export const GET_DESTINATIONS_REQUEST = 'TRAVELER_TEST/GET_DESTINATIONS_REQUEST';
export const RATE_DESTINATION_REQUEST = 'TRAVELER_TEST/RATE_DESTINATION_REQUEST';

export const SEND_ANSWERS_RESPONSE = 'TRAVELER_TEST/SEND_ANSWERS_RESPONSE';
export const FINISH_TEST_RESPONSE = 'TRAVELER_TEST/FINISH_TEST_RESPONSE';
export const GET_RESULT_RESPONSE = 'TRAVELER_TEST/GET_RESULT_RESPONSE';
export const GET_DESTINATIONS_RESPONSE = 'TRAVELER_TEST/GET_DESTINATIONS_RESPONSE';
export const RATE_DESTINATION_RESPONSE = 'TRAVELER_TEST/RATE_DESTINATION_RESPONSE';
