import React from 'react';

import { Button as AntdButton } from 'antd';

import './Button.scss';

export const Button = ({ id, type, text, clickHandler }) => {
  return (
    <div className="myluggage-button">
      <AntdButton id={id} onClick={clickHandler} type={type}>
        {text}
      </AntdButton>
    </div>
  );
};
