import React from 'react';

import './TextInput.scss';

export const TextInput = ({
  type = 'text',
  id,
  disabled = false,
  name,
  value,
  placeholder,
  label,
  error,
  onChange,
  onBlur,
}) => {
  return (
    <div className="text-input">
      {label && <span className="text-input__label">{label}</span>}
      <input
        className="text-input__field"
        id={id ?? name}
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <span className="text-input__error">{error}</span>}
    </div>
  );
};
