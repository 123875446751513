import React from 'react';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { Banner } from '../Banner';

import './ComparePreferencesHeader.scss';

import ComparePreferencesBanner from 'assets/traveler-type-result/banners/compare-preferences.png';
import ComparePreferencesMobileBanner from 'assets/traveler-type-result/banners/compare-preferences.mobile.png';

export const ComparePreferencesHeader = () => {
  const { localize } = useTranslation();

  return (
    <>
      <div className="compare-preferences__header">
        <Banner image={ComparePreferencesBanner} mobileImage={ComparePreferencesMobileBanner} />
        <h2 className="compare-preferences__title">{localize('traveler-test.result.compare-preferences')}</h2>
      </div>
    </>
  );
};
