import React from 'react';
import { useTranslation } from 'utils/hooks';

import { DownloadApp } from 'pages/apps/general/components/DownloadApp';

import './GetApp.scss';

import AppView from 'assets/get-app/app-view.png';
import AppViewMobile from 'assets/get-app/app-view.mobile.png';

export const GetApp = () => {
  const { localize } = useTranslation();

  return (
    <div className="get-app">
      <div className="get-app__view">
        <img src={AppView} alt="app view" className="get-app__app-view" />
        <img src={AppViewMobile} alt="app view" className="get-app__app-view-mobile" />
      </div>
      <div className="get-app__resources">
        <h2 className="get-app__title">{localize('app.get-app.get-ready')}</h2>
        <ul className="get-app__benefits">
          <li className="get-app__benefit">{localize('app.get-app.personalized-list')}</li>
          <li className="get-app__benefit">{localize('app.get-app.todo-list')}</li>
          <li className="get-app__benefit">{localize('app.get-app.trusted-destination')}</li>
        </ul>
        <DownloadApp buttonText={localize('app.get-app.plan-trip')} onlyBadges />
      </div>
    </div>
  );
};
