import React from 'react';
import * as uuid from 'uuid';

import './UserSteps.scss';
import ArrowNext from 'assets/arrow-next.svg';

export const UserSteps = ({ steps }) => {
  const renderUserStep = (number, label, showArrow = true) => {
    return (
      <div key={uuid.v4()} className="user-step">
        <h3 className="user-step__number">{number}</h3>
        <h3 className="user-step__label">{label}</h3>
        {showArrow && <img src={ArrowNext} alt="arrow" className="user-step__arrow" />}
      </div>
    );
  };

  return (
    <>
      <div className="user-steps">{steps.map((step, idx) => renderUserStep(`0${idx + 1}`, step, idx !== 2))}</div>
      <div className="user-steps__mobile">{steps.map((step, idx) => renderUserStep(`0${idx + 1}`, step, false))}</div>
    </>
  );
};
