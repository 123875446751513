import React from 'react';
import { Spin } from 'antd';

import './PageLoading.scss';

export const PageLoading = () => {
  return (
    <div className="page-loading">
      <Spin size="large" />
    </div>
  );
};
