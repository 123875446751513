import BargainHunterIcon from 'assets/traveler-test-icons/bargain_hunter.svg';
import CityLifeIcon from 'assets/traveler-test-icons/city_life.svg';
import CreativeInitiatorIcon from 'assets/traveler-test-icons/creative_initiator.svg';
import CultureFanIcon from 'assets/traveler-test-icons/culture_fan.svg';
import FestivalIcon from 'assets/traveler-test-icons/festival.svg';
import NavigatorIcon from 'assets/traveler-test-icons/navigator.svg';
import OrganiserIcon from 'assets/traveler-test-icons/organiser.svg';
import PhotographerIcon from 'assets/traveler-test-icons/photographer.svg';
import PlannerIcon from 'assets/traveler-test-icons/planner.svg';
import SportIcon from 'assets/traveler-test-icons/sport.svg';
import SurprisesIcon from 'assets/traveler-test-icons/surprises.svg';
import TimeKeeperIcon from 'assets/traveler-test-icons/time_keeper.svg';

import AdventurerIcon from 'assets/traveler-type-result/adventurer.png';
import CamperIcon from 'assets/traveler-type-result/camper.png';
import ExplorerIcon from 'assets/traveler-type-result/explorer.png';
import GlobetrotterIcon from 'assets/traveler-type-result/globetrotter.png';
import ThrillIcon from 'assets/traveler-type-result/thrill_seeker.png';
import VacationerIcon from 'assets/traveler-type-result/vacationer.png';
import VagrantIcon from 'assets/traveler-type-result/vagrant.png';
import VoyagerIcon from 'assets/traveler-type-result/voyager.png';

export const icons = {
  travelerPersonality: {
    bargain_hunter: BargainHunterIcon,
    city_life: CityLifeIcon,
    creative_initiator: CreativeInitiatorIcon,
    culture_fan: CultureFanIcon,
    festival: FestivalIcon,
    navigator: NavigatorIcon,
    organiser: OrganiserIcon,
    photographer: PhotographerIcon,
    planner: PlannerIcon,
    sport: SportIcon,
    surprises: SurprisesIcon,
    time_keeper: TimeKeeperIcon,
  },
  travelerType: {
    adventurer: AdventurerIcon,
    camper: CamperIcon,
    explorer: ExplorerIcon,
    globetrotter: GlobetrotterIcon,
    thrill_seeker: ThrillIcon,
    vacationer: VacationerIcon,
    vagrant: VagrantIcon,
    voyager: VoyagerIcon,
  },
};
