import * as type from './types';

export const setLoading = (loading) => ({
  type: type.SET_LOADING,
  payload: loading,
});

export const getUserGeolocationRequest = () => ({
  type: type.GET_USER_GEOLOCATION_REQUEST,
});

export const startTripRequest = ({
  guid,
  lang,
  origin,
  destination_name,
  destination_country_code,
  date_from,
  date_to,
}) => ({
  type: type.START_TRIP_REQUEST,
  payload: { guid, lang, origin, destination_name, destination_country_code, date_from, date_to },
});

export const startTripResponse = (response) => ({
  type: type.START_TRIP_RESPONSE,
  payload: response,
});

export const getUserGeolocationResponse = (response) => ({
  type: type.GET_USER_GEOLOCATION_RESPONSE,
  payload: response,
});
