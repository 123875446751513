export const en = {
  'routes.packing.start-trip': '/start-trip',

  'packing.start-trip.personalised-list': 'Personalised packing list for your trip',
  'packing.start-trip.type-destination': 'Type your destination',
  'packing.start-trip.departure': 'Departure',
  'packing.start-trip.return': 'Return',
  'packing.start-trip.man': 'Man',
  'packing.start-trip.woman': 'Woman',
  'packing.start-trip.start-packing': 'Start packing',
  'packing.start-trip.or': 'or',
  'packing.start-trip.hashtag': '#WakacjeBezObaw',
};
