import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { Banner } from '../Banner';

import './TravelPersonality.scss';

import TravelPersonalityBanner from 'assets/traveler-type-result/banners/travel-personality.png';
import TravelPersonalityMobileBanner from 'assets/traveler-type-result/banners/travel-personality.mobile.png';

export const TravelPersonality = ({ personality }) => {
  const { localize } = useTranslation();
  const companionship = personality?.companionship;

  return (
    <div className="travel-personality" id="travel-personality">
      <Banner image={TravelPersonalityBanner} mobileImage={TravelPersonalityMobileBanner} />
      <h2 className="travel-personality__title">{localize('traveler-test.result.travel-personality')}</h2>
      <div className="travel-personality__content">
        <div className="travel-personality__company company">
          <h3 className="company__title">{companionship?.heading}</h3>
          <div className="company__text">{companionship?.text}</div>
        </div>
        <div>
          <div className="travel-personality__good-leader good-leader">
            <h3 className="good-leader__title">{companionship?.talent?.heading}</h3>
            <div className="good-leader__text">{companionship?.talent?.text}</div>
          </div>
          {companionship?.beware?.text?.[0].length > 0 && (
            <div className="travel-personality__be-careful be-careful">
              <h3 className="be-careful__title">{companionship.beware.heading}</h3>
              <div className="be-careful__list">
                {companionship.beware.text.map((text) => (
                  <div key={uuid.v4()} className="be-careful__list-item">
                    <div className="be-careful__bullet" />
                    <div className="be-careful__text">{text}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
