import React, { Children } from 'react';
import { Switch } from 'react-router-dom';
import { useIntl } from 'react-intl';

export const LocalizedSwitch = ({ children }) => {
  const { formatMessage, locale } = useIntl();

  const localizeRoutePath = (path) => {
    switch (typeof path) {
      case 'undefined':
        return undefined;

      case 'object':
        return path.map((key) => `/${locale}${formatMessage({ id: key })}`);

      default:
        const isFallbackRoute = path === '*';
        return isFallbackRoute ? path : `/${locale}${formatMessage({ id: path })}`;
    }
  };

  return (
    <Switch>
      {Children.map(children, (child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: localizeRoutePath(child.props.path),
              to: localizeRoutePath(child.props.to),
            })
          : child;
      })}
    </Switch>
  );
};
