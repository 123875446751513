import { formalizeReturn } from './utils';

export const getFriends = async ({ lang, guid }) => {
  const response = await fetch(`/${lang}/profile/friends`, {
    headers: { guid },
  });

  return await formalizeReturn(response);
};

export const sendComparisonData = async ({ friendsIds, guid, lang }) => {
  const response = await fetch('/api/personality-test/compare', {
    method: 'POST',
    body: JSON.stringify({ personality_test_uuids: friendsIds }),
    headers: {
      'Content-Type': 'application/json',
      'app-language': lang,
      guid,
    },
  });

  return await formalizeReturn(response);
};

export const getComparison = async ({ comparison_uuid, guid, lang }) => {
  const response = await fetch(`/api/personality-test/compare/${comparison_uuid}`, {
    headers: {
      'app-language': lang,
      guid,
    },
  });
  return await formalizeReturn(response);
};
