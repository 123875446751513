import * as type from './types';

const initialState = {
  authorized: false,
  loading: true,

  me: null,
  deleteResult: null,
  updateResult: null,
  personalityResults: null,
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case type.PERSONALITY_RESULTS_REQUEST:
      return state;

    case type.PERSONALITY_RESULTS_RESPONSE:
      return {
        ...state,
        personalityResults: action.payload,
      };

    case type.SET_AUTHORIZED:
      return {
        ...state,
        authorized: action.payload,
      };

    case type.GET_ME_REQUEST:
      return state;

    case type.UPDATE_ME_REQUEST:
      return state;

    case type.DELETE_ME_REQUEST:
      return state;

    case type.GET_ME_RESPONSE:
      return {
        ...state,
        me: action.payload,
      };

    case type.UPDATE_ME_RESPONSE:
      return {
        ...state,
        updateResult: action.payload,
      };

    case type.DELETE_ME_RESPONSE:
      return {
        ...state,
        deleteResult: action.payload,
      };

    default:
      return state;
  }
};
