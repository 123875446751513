import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { reducers } from './reducers';
import { rootSagaWatcher } from './sagas';

const saga = createSagaMiddleware();

export const store = createStore(
  combineReducers(reducers),
  composeWithDevTools(applyMiddleware(saga))
);

saga.run(rootSagaWatcher);
