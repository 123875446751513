import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { setAnswer } from 'redux/traveler-test/actions';
import { formatId, getCenterOffset } from './helper';

import { Link } from 'react-scroll';

import './TravelerTypeQuestion.scss';
import Checkmark from 'assets/checkmark.svg';

export const TravelerTypeQuestion = ({ id, title }) => {
  const dispatch = useDispatch();
  const { localize } = useTranslation();
  const [selectedLevel, setSelectedLevel] = useState(0);

  const onSelectLevel = (level) => {
    setSelectedLevel(level);
    dispatch(setAnswer({ id, answer: level }));
  };

  const graduationLevel = (level) => {
    const classChecked =
      level === selectedLevel ? 'graduation__level--checked' : '';

    const render = () => (
      <div
        onClick={() => onSelectLevel(level)}
        className={`graduation__level ${classChecked}`}
        data-level={level}>
        <img className="checkmark" src={Checkmark} alt="checkmark" />
      </div>
    );

    return id % 12 !== 0 ? (
      <Link
        className="question__link"
        to={`question-${id + 1}`}
        smooth
        duration={500}
        offset={-getCenterOffset(window.innerHeight, 300)}>
        {render()}
      </Link>
    ) : (
      render()
    );
  };

  return (
    <div className="traveler-type-question" id={`question-${id}`}>
      <span className="question__id">{formatId(id)}</span>
      <h2 className="question__title">{title}</h2>
      <div className="question__graduation graduation">
        <span className="graduation__title title-lowest">
          {localize('traveler-test.title-lowest')}
        </span>
        <div className="graduation__container">
          {graduationLevel(1)}
          {graduationLevel(2)}
          {graduationLevel(3)}
          {graduationLevel(4)}
          {graduationLevel(5)}
          {graduationLevel(6)}
          {graduationLevel(7)}
        </div>
        <span className="graduation__title title-highest">
          {localize('traveler-test.title-highest')}
        </span>
        <div className="graduation__mobile">
          <span className="graduation__title--mobile title-lowest">
            {localize('traveler-test.title-lowest')}
          </span>
          <span className="graduation__title--mobile title-highest">
            {localize('traveler-test.title-highest')}
          </span>
        </div>
      </div>
    </div>
  );
};
