import { all } from 'redux-saga/effects';

import { travelerTypeSagaWatcher } from './traveler-test/saga.watcher';
import { testComparisonSagaWatcher } from './test-comparison/saga.watcher';
import { startTripSagaWatcher } from './start-trip/saga.watcher';
import { personalityResultsSagaWatcher } from './user/saga.watcher';

export function* rootSagaWatcher() {
  yield all([
    ...travelerTypeSagaWatcher,
    ...testComparisonSagaWatcher,
    ...startTripSagaWatcher,
    ...personalityResultsSagaWatcher,
  ]);
}
