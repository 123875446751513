import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks';

import { Progress } from 'antd';

import { pullProperties } from 'utils/helpers';
import { progressStates, createUnit } from './data';

import './TasksOverview.scss';

export const TasksOverview = ({ profiles, unit, unitLocalizationPrefix }) => {
  const { localize } = useTranslation();

  const units = Object.keys(profiles?.[0][unit] ?? {}).map((key) =>
    createUnit(localize(`${unitLocalizationPrefix}.${key}`), key)
  );

  const renderUserNames = () => {
    const names = pullProperties(profiles, 'name');
    return names?.map((name) => (
      <div key={uuid.v4()} className="tasks-overview__username">
        {name}
      </div>
    ));
  };

  const renderProgress = (key) => {
    return profiles?.map((profile) => {
      const { modifier, icon, strokeColor, trailColor } = progressStates.find(({ match }) =>
        match(profile?.[unit][key])
      );

      return (
        <div key={uuid.v4()} className={`tasks-overview__progress tasks-overview__progress--${modifier}`}>
          {icon && <img src={icon} alt="skill mastery" className="tasks-overview__icon" />}
          <div className="tasks-overview__progress-bar">
            <Progress
              type="circle"
              percent={profile?.[unit][key] ?? 0}
              width={77}
              trailColor={trailColor}
              strokeColor={strokeColor}
              format={(percent) => `${percent}%`}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="tasks-overview">
      <div className="tasks-overview__users">
        <div />
        {renderUserNames()}
      </div>
      {units?.map(({ label, percentName }) => (
        <div key={uuid.v4()} className="tasks-overview__unit">
          <div className="tasks-overview__label">{label}</div>
          {renderProgress(percentName)}
        </div>
      ))}
    </div>
  );
};
