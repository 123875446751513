export const pl = {
  'routes.packing.start-trip': '/start-trip',

  'packing.start-trip.personalised-list': 'Lista rzeczy do spakowania na każdy wyjazd',
  'packing.start-trip.type-destination': 'Dokąd się wybierasz?',
  'packing.start-trip.departure': 'Data wyjadu',
  'packing.start-trip.return': 'Data powrotu',
  'packing.start-trip.man': 'Mężczyzna',
  'packing.start-trip.woman': 'Kobieta',
  'packing.start-trip.start-packing': 'Spakuj się',
  'packing.start-trip.or': 'lub',
  'packing.start-trip.hashtag': '#WakacjeBezObaw',
};
