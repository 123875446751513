import * as type from './types';

export const setLoading = (loading) => ({
  type: type.SET_LOADING,
  payload: loading,
});

export const setAuthorized = (authorized) => ({
  type: type.SET_AUTHORIZED,
  payload: authorized,
});

export const personalityResultsRequest = ({ lang, guid }) => ({
  type: type.PERSONALITY_RESULTS_REQUEST,
  payload: { lang, guid },
});

export const personalityResultsResponse = (response) => ({
  type: type.PERSONALITY_RESULTS_RESPONSE,
  payload: response,
});

export const getMeRequest = ({ lang, guid }) => ({
  type: type.GET_ME_REQUEST,
  payload: { lang, guid },
});

export const updateMeRequest = ({
  lang,
  guid,
  name,
  marketing_consent,
  password,
  new_password,
  new_password_confirmation,
}) => ({
  type: type.UPDATE_ME_REQUEST,
  payload: { lang, guid, name, marketing_consent, password, new_password, new_password_confirmation },
});

export const deleteMeRequest = ({ guid, lang }) => ({
  type: type.DELETE_ME_REQUEST,
  payload: { guid, lang },
});

export const getMeResponse = (response) => ({
  type: type.GET_ME_RESPONSE,
  payload: response,
});

export const updateMeResponse = (response) => ({
  type: type.UPDATE_ME_RESPONSE,
  payload: response,
});

export const deleteMeResponse = (response) => ({
  type: type.DELETE_ME_RESPONSE,
  payload: response,
});
