const colors = ['#fecc4b', '#de5505', '#b1deb5', '#02664c', '#0497d5', '#1d5789'];

export const withColors = (array, slice = 0) => {
  if (!array) return array;

  let colorIdx = 0;
  const _colors = colors.slice(slice);

  return array.map((item) => {
    if (!_colors[colorIdx]) colorIdx = 0;
    return { ...item, color: _colors[colorIdx++] };
  });
};
