import * as type from './types';

export const startTest = (data) => ({
  type: type.START_TEST,
  payload: data,
});

export const setProgress = (percent) => ({
  type: type.SET_PROGRESS,
  payload: percent,
});

export const setLoading = (loading) => ({
  type: type.SET_LOADING,
  payload: loading,
});

export const setAnswers = (answers) => ({
  type: type.SET_ANSWERS,
  payload: answers,
});

export const setAnswer = (answer) => ({
  type: type.SET_ANSWER,
  payload: answer,
});

export const nextPage = () => ({
  type: type.NEXT_PAGE,
});

export const rateDestination = ({ id, rate }) => ({
  type: type.RATE_DESTINATION,
  payload: { id, rate },
});

// --- API REQUESTS ---
export const startTestRequest = ({ guid, lang }) => ({
  type: type.START_TEST_REQUEST,
  payload: { guid, lang },
});

export const sendAnswersRequest = ({ page, answers, testGuid, guid, lang }) => ({
  type: type.SEND_ANSWERS_REQUEST,
  payload: { page, answers, testGuid, guid, lang },
});

export const finishTestRequest = ({ answers, testGuid, guid, lang }) => ({
  type: type.FINISH_TEST_REQUEST,
  payload: { answers, testGuid, guid, lang },
});

export const getResultRequest = ({ lang, guid, testGuid }) => ({
  type: type.GET_RESULT_REQUEST,
  payload: { lang, guid, testGuid },
});

export const getDestinationsRequest = ({ lang, guid }) => ({
  type: type.GET_DESTINATIONS_REQUEST,
  payload: { lang, guid },
});

export const rateDestinationRequest = ({ guid, id, rate }) => ({
  type: type.RATE_DESTINATION_REQUEST,
  payload: { guid, id, rate },
});

// --- API RESPONSES ---
export const sendAnswersResponse = (response) => ({
  type: type.SEND_ANSWERS_RESPONSE,
  payload: response,
});

export const finishTestResponse = (response) => ({
  type: type.FINISH_TEST_RESPONSE,
  payload: response,
});

export const getResultResponse = (response) => ({
  type: type.GET_RESULT_RESPONSE,
  payload: response,
});

export const getDestinationsResponse = (response) => ({
  type: type.GET_DESTINATIONS_RESPONSE,
  payload: response,
});

export const rateDestinationResponse = (response) => ({
  type: type.RATE_DESTINATION_RESPONSE,
  payload: response,
});
