import React from 'react';
import * as uuid from 'uuid';
import { useTranslation } from 'utils/hooks/app/useTranslation';

import { TravelerFeature } from '../TravelerFeature';

import './TravelerResultHeader.scss';
import TravelerImage from 'assets/traveler-test/traveler.svg';

export const TravelerResultHeader = ({ travelerType, skills, specializations, icons }) => {
  const { localize } = useTranslation();

  return (
    <div className="traveler-result__header">
      <div className="traveler-result__header-container">
        <div className="traveler-result__image-container">
          <img
            src={icons.travelerType[travelerType?.key] ?? TravelerImage}
            alt="traveler"
            className="traveler-result__traveler-image"
          />
        </div>
        <div className="traveler-result__traveler-info traveler-info">
          <h2 className="traveler-info__title">{travelerType?.name}</h2>
          <div className="traveler-info__specialization specialization">
            <h2 className="traveler-info__label">{localize('traveler-test.result.traveler-specialization')}</h2>
            <div className="specialization__list">
              {specializations?.map(({ name, key }) => (
                <TravelerFeature key={uuid.v4()} title={name} icon={icons.travelerPersonality[key]} />
              ))}
            </div>
          </div>
          <div className="traveler-info__skills skills">
            <h2 className="traveler-info__label">{localize('traveler-test.result.traveler-skills')}</h2>
            <div className="skills__list">
              {skills?.map(({ name, key }) => (
                <TravelerFeature key={uuid.v4()} title={name} icon={icons.travelerPersonality[key]} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
