import { Swiper, Pagination } from 'swiper/js/swiper.esm';

export const getSwiperParams = () => ({
  Swiper,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    bulletElement: 'div',
  },
  width: window.innerWidth - 40,
  direction: 'horizontal',
  centeredSlidesBounds: true,
  spaceBetween: 10,
  updateOnWindowResize: true,
  containerModifierClass: 'strengths-swiper__container',
  setWrapperSize: true,
});
