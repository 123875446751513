import React from 'react';
import { ResultsCTA } from '../components/ResultsCTA';

export const guest = ({ localize, onClick, match }) => {
  const role = 'guest';

  const renderShareSection = () => (
    <ResultsCTA
      title={localize('traveler-test.result.CTA.discover-personality')}
      buttonText={localize('traveler-test.result.CTA.take-free-test')}
      onClick={onClick}
    />
  );

  const renderCompareSection = () => (
    <ResultsCTA
      title={localize('traveler-test.result.CTA.understand-preferences')}
      titleFontSize={30}
      buttonText={localize('traveler-test.result.CTA.take-test')}
      onClick={onClick}
    />
  );

  return { role, renderShareSection, renderCompareSection, match };
};
