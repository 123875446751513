import React from 'react';
import { useTranslation } from 'utils/hooks';

import './TestComparisonHeader.scss';

export const TestComparisonHeader = () => {
  const { localize } = useTranslation();

  return (
    <div className="comparison-header">
      <h2 className="comparison-header__title">{localize('traveler-test.comparison.compare-profile')}</h2>
      <h3 className="comparison-header__subtitle">{localize('traveler-test.comparison.header.subtitle')}</h3>
    </div>
  );
};
