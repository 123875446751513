import CrownIcon from 'assets/traveler-test/crown.svg';
import DangerIcon from 'assets/traveler-test/danger.svg';

const createState = (modifier, icon, strokeColor, trailColor, match) => ({
  modifier,
  icon,
  strokeColor,
  trailColor,
  match,
});

export const progressStates = [
  createState('danger', DangerIcon, '#de5505', '#e7e7e7', (percent) => percent < 25),
  createState('middle', null, '#fe9c47', '#f8dac0', (percent) => percent >= 25 && percent <= 75),
  createState('perfect', CrownIcon, '#b1deb5', '#e7e7e7', (percent) => percent > 75),
];

export const createUnit = (label, percentName) => ({ label, percentName });
